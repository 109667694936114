import React from "react";
import img from "../../assets/images/thank-you.webp";
import icon from "../../assets/images/small/vector.webp";
import withRouter from "../../components/Common/withRouter"
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash"
import { useEffect, useRef, useState } from "react"
import api from "../../constants/api"
import {
  getData,
} from "../../store/AppMaster/actions"
import { useParams } from "react-router-dom";
const ThankYou = props => {
  const params = useParams();
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }))
  const dispatch = useDispatch()
  const [isApiCall, setIsApiCall] = useState(0);
  const [isUpdateData, setIsUpdateData] = useState(false)
  const [billingData, setBillingData] = useState('')

  useEffect(() => {
    if (isApiCall === 0) {
      setIsUpdateData(true);
      const requestData = { order_id: params.order_id };
      dispatch(getData(requestData, props.router.navigate, api.BillingInfo));
      setIsApiCall(1);
    }
  }, [isApiCall, dispatch, props.router.navigate, params.order_id]);

  if (!isEmpty(data) && !isEmpty(data.billingDetail) && isUpdateData) {
    setBillingData(data);
    setIsUpdateData(false);
  }
  const [copySuccess, setCopySuccess] = useState(false);
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false); // Clear the message after 3 seconds
      }, 2000);
    }).catch(err => {
      setCopySuccess(false);
    });
  };
  return (
    <div className="bg-neutral pt-32 md:pt-48 pb-20  bg-no-repeat">
      <div className="container">
        <h2 className="text-3xl md:text-[60px] text-white font-body font-normal text-center">
          Thank you
        </h2>
        <p className="text-lg text-white font-body font-light text-center mt-6 hidden md:block">Thanks for choosing Mepass! Your Mepass Details are right here. See you at the event!</p>
        <img src={img} alt="" className="m-auto my-[24px]" />

        {/* ----------------card-start--------------- */}
        <div className="hidden md:block">
          <div className="bg-black p-5 rounded-lg">
            <h3 className="text-[22px] md:text-2xl text-white font-body font-normal mb-5">
              Booking ID : {params.order_id ? params.order_id : ''}
              <img src={icon} alt="" className="inline-block ml-4" onClick={() => copyToClipboard(params.order_id ? params.order_id : '')} />
              {copySuccess && <span className="text-[#00FF00] text-sm font-body font-normal ml-2">Copied!</span>}
            </h3>
            <div className="flex items-center justify-between">
              <h3 className="text-[20px] text-white font-body font-semibold">
                Ticket Quantity
              </h3>
              <span className="text-[20px] text-white font-body font-semibold">
                {billingData && billingData.totalPass && billingData.totalPass}
              </span>
            </div>
            <div className="border-y border-[#F6F6F6] py-5 my-5">
              {/* <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  Ticket Amount
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹ {billingData && billingData.totalPass && billingData.totalAmount}
                </p>
              </div> */}
              {billingData && billingData.billingDetail && billingData.billingDetail.map((item, index) => {
                return (
                  <div key={index} className="flex items-center justify-between mb-2">
                    <p className="text-base text-[#DCDCDC] font-body font-normal">
                      {item.pass_count} x {item.passName}
                    </p>
                    <p className="text-base text-[#DCDCDC] font-body font-medium">
                      ₹ {item.pass_amount}
                    </p>
                  </div>
                )
              })}
            </div>
            <div className="flex items-center justify-between">
              <span className="text-xl text-white font-body font-semibold">
                Total Amount
              </span>
              <p className="text-xl text-white font-body font-semibold">
                ₹{billingData && billingData.totalAmount && billingData.totalAmount}
              </p>
            </div>
          </div>
        </div>
        {/* ----------------card-end---------------- */}

        {/* --------------------mobile-view-start------------------- */}
        <div className="md:hidden block">
          <div className="bg-black p-5 rounded-lg">
            <h3 className="text-[22px] md:text-2xl text-white font-body font-normal mb-5">
              Booking ID : {params.order_id ? params.order_id : ''}
              <img src={icon} alt="" className="inline-block ml-4" onClick={() => copyToClipboard(params.order_id ? params.order_id : '')} />
              {copySuccess && <span className="text-[#00FF00] text-sm font-body font-normal ml-2">Copied!</span>}
            </h3>
            <div className="border-t border-[#5A5A5A] pb-5"></div>
            <div className="flex items-center justify-between">
              <h3 className="text-[20px] text-white font-body font-semibold">
                Ticket Quantity
              </h3>
              <span className="text-[20px] text-white font-body font-semibold">
                {billingData && billingData.totalPass && billingData.totalPass}
              </span>
            </div>
            <div className="border-y border-[#F6F6F6] py-5 my-5">
              {/* <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  Ticket Amount
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹ {billingData && billingData.totalPass && billingData.totalAmount}
                </p>
              </div> */}
              {billingData && billingData.billingDetail && billingData.billingDetail.map((item, index) => {
                return (
                  <div key={index} className="flex items-center justify-between mb-2">
                    <p className="text-base text-[#DCDCDC] font-body font-normal">
                      {item.pass_count} x {item.passName}
                    </p>
                    <p className="text-base text-[#DCDCDC] font-body font-medium">
                      ₹ {item.pass_amount}
                    </p>
                  </div>
                )
              })}
            </div>
            <div className="flex items-center justify-between">
              <span className="text-xl text-white font-body font-semibold">
                Total Amount
              </span>
              <p className="text-xl text-white font-body font-semibold">
                ₹{billingData && billingData.totalAmount && billingData.totalAmount}
              </p>
            </div>
          </div>
        </div>
        {/* --------------------mobile-view-end------------------- */}
      </div>
    </div>
  );
}

export default withRouter(ThankYou);
