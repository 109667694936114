import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import { convertToAmPm } from "../../common/commonFunction";

const SelectPass = ({
    pass,
    handleIncrement,
    handleDecrement,

}) => {

    return (

        <>
            <div className="bg-[#131313] p-5 rounded-lg mt-7">
                <div className="flex items-center justify-between">
                    <h3 className="text-lg md:text-3xl text-secondary font-body font-semibold">
                        {pass.name}
                    </h3>
                    <div>
                        <div className="flex justify-end">
                            {pass.amount > 0 && pass.amount != pass.amount_after_discount ? (
                                <h5 className="text-sm md:text-lg font-medium text-[#c8c8c8] mr-3">
                                    <del>₹ {pass.amount}</del>
                                </h5>
                            ) : ''}
                            <span className="text-lg md:text-3xl text-secondary font-body font-semibold uppercase">
                                ₹{pass.amount_after_discount}
                            </span>
                        </div>
                        {pass.percentage > 0 && (
                            <p className='text-sm md:text-lg font-medium text-[#388e3c] ml-3 text-end'>{pass.percentage}% off</p>
                        )}
                    </div>
                </div>

                <div className="block md:flex items-center gap-4 mt-3">
                    <div className="flex-auto w-full lg:w-[85%] md:w-[71%]">
                        <p className={`text-white w-full lg:w-[88%] ${pass.notes && pass.notes.includes('<ul>') ? "bullet-point" : pass.notes && pass.notes.includes('<ol>') ? "number-point" : ""}`} dangerouslySetInnerHTML={
                            {
                                __html: pass.notes
                            }
                        }>

                        </p>
                    </div>
                    <div className="flex-none w-[84%] md:w-[29%] lg:w-[21%] xl:w-[15%] pt-5 md:pt-0">

                        {pass.limit && pass.limit > 0 ? (
                            <div className="border border-white py-3 px-5 rounded-md">
                                <div className="relative flex items-center gap-4">
                                    <button
                                        type="button"
                                        onClick={
                                            () => handleDecrement(pass.id, pass.quantity)
                                        }
                                        className="bg-primary w-[36px] h-[36px] flex items-center justify-center  text-white rounded-md"
                                    >
                                        <i className="fas fa-minus"></i>
                                    </button>
                                    <input
                                        type="text"
                                        id="quantity-input"
                                        value={pass.quantity ? pass.quantity : 0}
                                        className="w-[36px] h-[36px] flex items-center justify-center text-center text-3xl font-body font-normal text-white bg-transparent"
                                        placeholder="999"
                                        required
                                        readOnly
                                    />
                                    <button
                                        type="button"
                                        onClick={
                                            () => handleIncrement(pass.id, pass.quantity)
                                        }
                                        className="bg-primary w-[36px] h-[36px] flex items-center justify-center  text-white rounded-md"
                                    >
                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-3 md:px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase">Sold</button>
                        )}
                    </div>

                </div>
            </div>
        </>

    )
}
SelectPass.propTypes = {
    pass: PropTypes.object,
}
export default SelectPass
