import React from 'react';
import { Link } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import EventView from "../../components/Event/View";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import PropTypes from 'prop-types';
// import required modules
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import { isEmpty } from 'lodash';

const Event = ({ events, props, section_title }) => {
    return (
        <>

            <div className="bg-neutral pt-12">
                <div className="container">
                    {events && events.length > 0 ? (
                        <>
                            <div>
                                <div className='flex items-center justify-between gap-4 mb-10'>
                                    <h2 className='text-[22px] md:text-[40px] font-body font-normal tracking-[0.3rem] text-white uppercase'>{section_title}</h2>
                                    <button onClick={
                                        () => {
                                            props.router.navigate('/events')
                                        }
                                    } className='text-white text-sm md:text-[16px] font-normal tracking-[0.1em] md:tracking-[.125em] py-[8px] px-[10px] md:py-3 md:px-5 rounded-full font-body bg-black whitespace-nowrap'>View More
                                        <i className="fas fa-arrow-right ml-1 md:ml-2"></i>
                                    </button>
                                </div>
                            </div>
                            <Swiper
                                slidesPerView={3}
                                spaceBetween={30}
                                freeMode={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    
                                }}
                                modules={[Autoplay, FreeMode, Pagination]}
                                className="mySwiper !pb-5"
                            >
                                {events && events.map((singleEvent, index) => (
                                    <>
                                        {!isEmpty(singleEvent.event) ? (
                                            <SwiperSlide>
                                                <Link to={"/events/" + singleEvent.event.slug}>
                                                    <EventView singleEvent={singleEvent.event} props={props} key={index} />
                                                </Link>
                                            </SwiperSlide>
                                        ) : ''}
                                    </>
                                ))}

                            </Swiper>
                        </>
                    ) : ''}
                </div>

            </div>

        </>
    );
}
Event.propTypes = {
    events: PropTypes.array,
    props: PropTypes.object,
    section_title: PropTypes.string
}
export default Event;
