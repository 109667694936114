import { useState, useEffect } from 'react';
import React from "react";
import userImageDefault from '../../assets/images/default/user-image.png';
import { numberFormatter } from "../../common/commonFunction";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getData, clearData, addUpdateNewData } from "../../store/AppMaster/actions";
import api from "../../constants/api";
import { isEmpty } from "lodash";

const ArtistView = ({ singleArtist, pageTitle }) => {

    const params = useParams();
    const dispatch = useDispatch();
    const { data } = useSelector(state => ({
        data: state.AppMaster.data,
    }));

    const [isAddData, setIsAddData] = useState(false);
    const addToFavorite = (favorite_id) => {
        setIsAddData(true);
        const newData = {
            favorite_id: favorite_id,
            type: 'Artist',
        }
        dispatch(addUpdateNewData(newData, '', api.AddFavorites));
    }

    if (!isEmpty(data) && !isEmpty(data.favoritesAdd) && isAddData) {
        setIsAddData(false);
        singleArtist.is_favorite = 'Yes';
    }

    // Remove favorite
    const [isRemoveData, setIsRemoveData] = useState(false);
    const removeToFavorite = (favorite_id) => {
        setIsRemoveData(true);
        const newData = {
            favorite_id: favorite_id,
            type: 'Artist',
        }
        dispatch(addUpdateNewData(newData, '', api.RemoveFavorites));
    }
    if (!isEmpty(data) && !isEmpty(data.favoritesDelete) && isRemoveData) {
        setIsRemoveData(false);
        singleArtist.is_favorite = 'No';
    }

    return (
        <div className="bg-[#131313] p-2 rounded-lg">
            <div className='flex items-center justify-between'>
                <h3 className='text-white text-sm md:text-base font-body font-medium'>{singleArtist.name}</h3>
                {singleArtist.rating > 0 ? (
                    <div className='bg-primary py-[2px] px-[5px] inline-block rounded-full text-nowrap'>
                        <Link to={"/artiest/" + singleArtist.slug}>
                            <p className='text-white text-[14px] font-body'>
                                <i className="fas fa-star mr-2 text-secondary"></i><b>{singleArtist.rating}</b></p>
                        </Link>
                    </div>
                ) : ''}
            </div>
            <p className='text-white text-[16px] font-body font-normal mt-2'>
                {singleArtist.category ? singleArtist.category : ''}
            </p>
            <div>
                <div className="flex items-center mt-3 justify-between">
                    {singleArtist.fanbase && singleArtist.fanbase ?
                        <p className='text-white text-sm font-body font-medium mr-2'>
                            <i className="fas fa-user-friends mr-2"></i>
                            Fan Base: {numberFormatter(singleArtist.fanbase)}
                        </p>
                        : ''}
                </div>
            </div>

            <div className='relative'>
                <Link to={"/artiest/" + singleArtist.slug}>
                    <img src={singleArtist.image ? singleArtist.image : userImageDefault} alt="" className="w-full rounded-lg mt-4 aspect-square object-cover" />
                </Link>
            </div>

            {localStorage.getItem("publicMePassUser") ? (
                singleArtist.is_favorite === 'Yes' ? (
                    <button
                        onClick={() => removeToFavorite(singleArtist.id)}
                        className="border border-secondary text-secondary text-[10px] md:text-sm lg:text-xs xl:text-sm xxl:text-base font-normal tracking-[.25em] py-3 px-2 rounded-[6px] md:rounded-lg w-full uppercase mt-2"

                    >
                        Remove Favorite <i className="fal fa-minus ml-2"></i>
                    </button>
                ) : (
                    <button
                        onClick={() => addToFavorite(singleArtist.id)}
                        className="border border-secondary text-secondary text-[10px] md:text-sm lg:text-xs xl:text-sm xxl:text-base font-normal tracking-[.25em] py-3 px-2 rounded-[6px] md:rounded-lg w-full uppercase mt-2"

                    >
                        Add To Favorite <i className="fal fa-plus ml-2"></i>
                    </button>
                )
            ) : ''}
        </div>
    );
}

export default ArtistView;
