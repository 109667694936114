import React from "react";
import bussines from "../../assets/images/bussines.png";
function Bussines() {
  return (
    <div
      className="bg-[#FFFFFF33] pt-[20px] pb-[20px] md:pt-14 md:pb-14 bg-right md:bg-center bg-no-repeat border-t border-[#FFFFFF33]"
      style={{ backgroundImage: `url(${bussines})` }}
    >
      <div className="container ">
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-8 items-center">
          <div className="col-span-2">
            <h4 className="text-3xl md:text-5xl font-bold font-body text-white">
              For Business
            </h4>
            <p className="text-base font-body text-white mt-3">
              Stay updated with our latest promotions, discounts and special
              offers.
            </p>
          </div>
          <div>
            <button className="bg-primary text-white font-light text-base font-body px-8 py-3 rounded-lg tracking-[0.3rem] block ml-auto mt-3 uppercase">
              Get in Touch
              <i className="fas fa-arrow-right ml-4"></i>
            </button>
          </div>
          {/* <div>
                       <div className='bg-[#FFFFFF33] rounded-lg py-3 pr-3 md:pl-1 lg:pl-7 pl-3 flex items-center justify-between mt-4 md:mt-0'>
                       <input type="text" placeholder="Enter your mail" className="text-white font-normal text-lg font-body bg-transparent border-0 placeholder:text-white focus:outline-none" />
                       <button className="bg-primary text-white font-normal md:text-sm lg:text-base font-body md:px-3 md:py-2 lg:px-5 lg:py-2 tracking-[.125em] hidden md:block whitespace-nowrap rounded-lg uppercase">Submit
                       <i className="fas fa-arrow-right md:ml-1 lg:ml-2"></i>
                       </button>
                       </div>
                       <button className="bg-primary text-white font-normal text-base font-body px-5 py-2 rounded-lg tracking-[.125em] block md:hidden ml-auto mt-3 uppercase">Submit
                       <i className="fas fa-arrow-right ml-2"></i>
                       </button>
                       </div> */}
        </div>
      </div>
    </div>
  );
}

export default Bussines;
