import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import { convertToAmPm } from "../../common/commonFunction";

const EventDetailsRes = ({
    event,
}) => {

    return (

        <>
            <h2 className="text-2xl text-white font-body font-medium text-center">
                {event.name ? event.name : ''}
            </h2>
            <div className="block items-center mt-3">
                <p className="text-white text-base font-body font-medium">
                    <i className="fas fa-map-marker-alt mr-2"></i>
                    {event.address ? event.address : ''}, {event.city ? event.city : ''}
                </p>
                <p className="text-white text-base font-body font-medium mr-2 mt-3 md:mt-0">
                    <i className="fas fa-clock mr-2"></i>
                    {event.start_time ? convertToAmPm(event.start_time) : ''}
                </p>
            </div>
        </>

    )
}
EventDetailsRes.propTypes = {
    event: PropTypes.object,
}
export default EventDetailsRes
