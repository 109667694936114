import React from "react";
import userImageDefault from '../../assets/images/default/user-image.png';

const SponsorView = ({ singleSponsor }) => {
    return (
        <>
            <div className="text-center lg:max-w-[260px]">
                <img src={singleSponsor.image ? singleSponsor.image : userImageDefault} alt="" className="w-full rounded-lg" />
                <h5 className="text-white text-base md:text-base font-light mt-3">
                    {singleSponsor.name}
                </h5>
            </div>
        </>
    )
}

export default SponsorView
