import PropTypes from "prop-types";
import React from "react";
import withRouter from "../../components/Common/withRouter";
import { useLocation } from 'react-router-dom';
// Layout Related Components
import Footer from "../Layout/Footer";
import ComingSoonFooter from "../Layout/ComingSoonFooter";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3"
import { useMatch } from "react-router-dom";
const Layout = props => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const loginMatch = useMatch("/login");
  const signupMatch = useMatch("/sign-up");
  return (
    <>
      {loginMatch ?
        <React.Fragment>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
            {props.children}
            <Footer />
          </GoogleReCaptchaProvider>
        </React.Fragment>
        : signupMatch ?
          <React.Fragment>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
              {props.children}
              <Footer />
            </GoogleReCaptchaProvider>
          </React.Fragment>
          :
          <React.Fragment>
            {props.children}
            {(currentRoute === '/coming-soon' || currentRoute === '/')
              ? <ComingSoonFooter />
              : <Footer />}
          </React.Fragment>
      }
    </>

  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default withRouter(Layout);
