import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import user from "../../assets/images/default/user-image.png";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import EventView from "../Event/View";
import { Link } from "react-router-dom";

const Interest = ({
    props,
    isApiCall,
    setIsApiCall,
}) => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => ({
        data: state.AppMaster.data,
    }));

    const [isUpdateData, setIsUpdateData] = useState(false);
    const [isUpdateFavoriteEventData, setIsUpdateFavoriteEventData] = useState(false);
    const [favoriteList, setFavoriteList] = useState([]);
    const [artistList, setArtistList] = useState([]);
    const [favoriteEventList, setFavoriteEventList] = useState([]);
    const [totalArtist, setTotalArtist] = useState(0);
    const [totalFavoriteEvent, setTotalFavoriteEvent] = useState(0);
    const [totalFavorite, setTotalFavorite] = useState(0);
    const [isUpdateFavoriteData, setIsUpdateFavoriteData] = useState(false);
    const [activeTab, setActiveTab] = useState(1); // Renamed activeButton to activeTab
    const [requestData, setRequestData] = useState({
        start: 0,
        limit: 10,
        type: 'Artist',
    });
    const [requestFavoriteData, setRequestFavoriteData] = useState({
        start: 0,
        limit: 100,
        type: 'Artist',
    });
    const [requestFavoriteEventData, setRequestFavoriteEventData] = useState({
        start: 0,
        limit: 10,
        type: 'Event',
    });
    // Function to handle button click and change active tab
    const handleTabClick = (tabId) => {
        if (tabId === 1) {
            setArtistList([]);
            setTotalArtist(0);
            setTotalFavorite(0);
            setFavoriteList([]);
            getArtistList();
            getFavoriteList();
        } else {
            setTotalFavoriteEvent(0);
            setFavoriteEventList([]);
            getFavoriteEventList();
        }
        setActiveTab(tabId);
    };
    useEffect(() => {
        if (isApiCall === 0) {
            if (activeTab === 1) {
                getArtistList();
                getFavoriteList();
            } else {
                getFavoriteEventList();
            }
            setIsApiCall(1);
        }
    }, [isApiCall, dispatch, props.router.navigate]);


    const getFavoriteEventList = () => {
        setTotalFavoriteEvent(0);
        setFavoriteEventList([]);
        setIsUpdateFavoriteEventData(true);
        dispatch(getData(requestFavoriteEventData, props.router.navigate, api.addFavoritesArtistList));
    }

    const getArtistList = () => {
        setArtistList([]);
        setTotalArtist(0);
        setIsUpdateData(true);
        dispatch(getData(requestData, props.router.navigate, api.ArtistList));
    }
    const getFavoriteList = () => {
        setTotalFavorite(0);
        setFavoriteList([]);
        setIsUpdateFavoriteData(true);
        dispatch(getData(requestFavoriteData, props.router.navigate, api.addFavoritesArtistList));
    }

    if (!isEmpty(data) && !isEmpty(data.allArtists) && isUpdateData) {
        setArtistList(prevArtists => [...prevArtists, ...data.allArtists]);
        setTotalArtist(data.filter_count);
        setIsUpdateData(false);
    }
    if (!isEmpty(data) && !isEmpty(data.favoriteList) && isUpdateFavoriteData) {
        setFavoriteList(prevFavorite => [...prevFavorite, ...data.favoriteList]);
        setTotalFavorite(data.filter_count);
        setIsUpdateFavoriteData(false);
    }
    if (!isEmpty(data) && !isEmpty(data.favoriteList) && isUpdateFavoriteEventData) {
        setFavoriteEventList(prevFavorite => [...prevFavorite, ...data.favoriteList]);
        setTotalFavoriteEvent(data.filter_count);
        setIsUpdateFavoriteEventData(false);
    }

    const loadMoreArtist = () => {
        setRequestData({
            start: requestData.start + 10,
            limit: 10,
        });
        getArtistList();
    }
    const loadMoreFavoriteEvent = () => {
        setRequestFavoriteEventData({
            start: requestFavoriteEventData.start + 10,
            limit: 10,
        });
        getFavoriteEventList();
    }
    const loadMoreFavorite = () => {
        setRequestFavoriteData({
            start: requestFavoriteData.start + 10,
            limit: 10,
        });
        getFavoriteList();
    }
    const [isAddData, setIsAddData] = useState(false);
    const addToFavorite = (artistId) => {
        setIsAddData(true);
        const newData = {
            favorite_id: artistId,
            type: 'Artist',
        }
        dispatch(addUpdateNewData(newData, props.router.navigate, api.AddFavorites));
    }

    if (!isEmpty(data) && !isEmpty(data.favoritesAdd) && isAddData) {
        setIsAddData(false);
        getFavoriteList();
    }

    // Remove favorite
    const [isRemoveData, setIsRemoveData] = useState(false);
    const removeToFavorite = (artistId) => {
        setIsRemoveData(true);
        const newData = {
            favorite_id: artistId,
            type: 'Artist',
        }
        dispatch(addUpdateNewData(newData, props.router.navigate, api.RemoveFavorites));
    }
    if (!isEmpty(data) && !isEmpty(data.favoritesDelete) && isRemoveData) {
        setIsRemoveData(false);
        getFavoriteList();
    }
    return (

        <>
            <h2 className="text-white text-2xl font-body font-light uppercase tracking-[0.2rem]">
                Select Interest
            </h2>
            <div className="bg-[#1B1B1B] p-2 rounded-lg mt-5">
                <div className="flex items-center justify-between">
                    <button
                        className={`button ${activeTab === 1
                            ? "active-button bg-secondary rounded-md !text-black font-semibold"
                            : ""
                            } py-1 text-white font-body font-normal text-[18px] w-1/2`}
                        onClick={() => handleTabClick(1)}
                    >
                        Artist
                    </button>
                    <button
                        className={`button ${activeTab === 2
                            ? "active-button !bg-secondary rounded-md !text-black font-semibold"
                            : ""
                            } bg-transparent py-1 text-white font-body font-normal text-[18px] w-1/2`}
                        onClick={() => handleTabClick(2)}
                    >
                        Event
                    </button>
                </div>
            </div>
            <div className="bg-[#1B1B1B] p-[15px] md:p-[29px] xl:p-[50px] rounded-lg mt-5">
                {activeTab === 1 ? (
                    <>

                        <div className="flex flex-wrap items-center gap-3">
                            {favoriteList && favoriteList.map((favorite, indexKey) => (
                                <div key={indexKey} className="text-white text-sm font-body font-normal p-2 inline bg-black rounded-full border border-black cursor-default">
                                    {favorite.name}<i className="fas fa-times ml-2.5 cursor-pointer"
                                        onClick={
                                            () => {
                                                removeToFavorite(favorite.id);
                                            }
                                        }></i>
                                </div>
                            ))}
                        </div>

                        {totalFavorite > favoriteList.length ? (
                            <div className="flex justify-center mt-5">
                                <button
                                    className="button bg-secondary text-black font-body font-normal text-[18px] py-2 px-5 rounded-md"
                                    onClick={loadMoreFavorite}
                                >
                                    Load More
                                </button>
                            </div>
                        )
                            : ''}

                        <div className="mt-8">
                            <div className="grid grid-cols-3 md:grid-cols-3 xl:grid-cols-6 gap-4">
                                {artistList && artistList.map((artist, index) => (
                                    <div key={index} className="text-center cursor-pointer" onClick={
                                        () => {
                                            addToFavorite(artist.id);
                                        }
                                    }>
                                        <img
                                            src={artist.image ? artist.image : user}
                                            alt=""
                                            className="border border-secondary rounded-full"
                                        />
                                        <h4 className="text-white text-sm font-body font-medium mt-2">
                                            {artist.name}
                                        </h4>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex justify-center mt-5">
                            {totalArtist > artistList.length ? (
                                <button
                                    className="button bg-secondary text-black font-body font-normal text-[18px] py-2 px-5 rounded-md"
                                    onClick={loadMoreArtist}
                                >
                                    Load More
                                </button>
                            )
                                : ''}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-10">
                            {favoriteEventList && favoriteEventList.map((singleEvent, index) => (
                                <Link to={"/events/" + singleEvent.slug}>
                                    <EventView singleEvent={singleEvent} key={index} />
                                </Link>
                            ))}
                             </div>
                            {favoriteEventList.length === 0 && (
                                <div className="text-white text-base font-body font-normal text-center">
                                    No favorite event found.
                                </div>
                            )}
                       
                        <div className="flex justify-center mt-5">
                            {totalFavoriteEvent > favoriteEventList.length ? (
                                <button
                                    className="button bg-secondary text-black font-body font-normal text-[18px] py-2 px-5 rounded-md"
                                    onClick={loadMoreFavoriteEvent}
                                >
                                    Load More
                                </button>
                            )
                                : ''}
                        </div>
                    </>
                )}
            </div>
        </>

    )
}
Interest.propTypes = {
    props: PropTypes.object,
}
export default Interest
