import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { convertToAmPm, convertToDate } from "../../common/commonFunction";
import api from "../../constants/api";
import { getData } from "../../store/AppMaster/actions";

const TicketHistory = ({
    props,
    isApiCall,
    setIsApiCall,
}) => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => ({
        data: state.AppMaster.data,
    }));

    const [activeButton, setActiveButton] = useState(1);
    // Function to handle button click and change active button
    const handleButtonClick = (buttonId) => {
        if (buttonId === 2) {
            getTicketHistory();
        } else {
            getActivePass();
        }
        setActiveButton(buttonId);
    };

    const [isUpdateActivePassData, setIsUpdateActivePassData] = useState(false);
    const [isUpdateTicketHistoryData, setIsUpdateTicketHistoryData] = useState(false);
    const [activePass, setActivePass] = useState([]);
    const [ticketHistory, setTicketHistory] = useState([]);
    const [totalActivePass, setTotalActivePass] = useState(0);
    const [totalTicketHistory, setTotalTicketHistory] = useState(0);
    const [requestActivePassData, setRequestActivePassData] = useState({
        start: 0,
        limit: 10,
        type: 'ActivePass'
    });
    const [requestTicketHistoryData, setRequestTicketHistoryData] = useState({
        start: 0,
        limit: 10,
        type: 'PassHistory'
    });
    useEffect(() => {
        if (isApiCall === 0) {
            if (activeButton === 1) {
                setActivePass([]);
                setTotalActivePass(0);
                getActivePass();
            } else {
                setTicketHistory([]);
                setTotalTicketHistory(0);
                getTicketHistory();
            }
            setIsApiCall(1);
        }
    }, [isApiCall, dispatch, props.router.navigate]);

    const getActivePass = () => {
        setIsUpdateActivePassData(true);
        dispatch(getData(requestActivePassData, props.router.navigate, api.getTicketHistory));
    }

    const getTicketHistory = () => {
        setIsUpdateTicketHistoryData(true);
        dispatch(getData(requestTicketHistoryData, props.router.navigate, api.getTicketHistory));
    }

    if (!isEmpty(data) && !isEmpty(data.passHistoryLis) && isUpdateActivePassData) {
        setActivePass(prevPass => [...prevPass, ...data.passHistoryLis]);
        setTotalActivePass(data.filter_count);
        setRequestActivePassData({
            start: requestActivePassData.start + 10,
            limit: 10,
            type: 'ActivePass'
        });
        setIsUpdateActivePassData(false);
    }
    if (!isEmpty(data) && !isEmpty(data.passHistoryLis) && isUpdateTicketHistoryData) {
        setTicketHistory(prevPass => [...prevPass, ...data.passHistoryLis]);
        setTotalTicketHistory(data.filter_count);
        setRequestTicketHistoryData({
            start: requestTicketHistoryData.start + 10,
            limit: 10,
            type: 'PassHistory'
        });
        setIsUpdateTicketHistoryData(false);
    }

    const loadMoreActivePass = () => {
        getActivePass();
    }

    const loadMoreTicketHistory = () => {
        getTicketHistory();
    }
    return (
        <>
            <h2 className="text-white text-2xl font-body font-light uppercase tracking-[0.2rem]">
                Ticket Details
            </h2>
            <div className="bg-[#1B1B1B] p-2 rounded-lg mt-5">
                <div className="flex items-center justify-between">
                    <button
                        className={`button ${activeButton === 1
                            ? "active-button bg-secondary rounded-md !text-black font-semibold"
                            : ""
                            } py-1 text-white font-body font-normal text-[18px] w-1/2`}
                        onClick={() => handleButtonClick(1)}
                    >
                        Active Pass
                    </button>
                    <button
                        className={`button ${activeButton === 2
                            ? "active-button !bg-secondary rounded-md !text-black font-semibold"
                            : ""
                            } bg-transparent py-1 text-white font-body font-normal text-[18px] w-1/2`}
                        onClick={() => handleButtonClick(2)}
                    >
                        Pass History
                    </button>
                </div>
            </div>
            <div className="bg-[#1B1B1B] p-[25px] md:p-[50px] rounded-lg mt-5">
                {activeButton === 1 ? (
                    <>
                        {activePass.map((item, index) => (
                            <div key={index} className="border border-[#5A5A5A] p-[20px] md:p-[30px] rounded-lg mb-4">
                                <div className="flex items-center justify-between">
                                    <h3 className="text-secondary md:text-white text-xl lg:text-3xl font-body font-normal flex-1">
                                        {item.created_at ? convertToDate(item.created_at) : ''}
                                    </h3>
                                    <span className="text-secondary text-xl lg:text-3xl font-body font-semibold flex-none">
                                        ₹{item.pricing_details && item.pricing_details.net_amount ? item.pricing_details.net_amount : 0}
                                    </span>
                                </div>
                                <h4 className="text-white text-xl font-body font-semibold mt-2">
                                    {item.event_name}
                                </h4>
                                <div className="block md:flex items-center mt-3 gap-6">
                                    <p className="text-white text-base font-body font-medium">
                                        {item.event_date ? <i className="fas fa-calendar mr-2"></i> : ''}
                                        {item.event_date ? convertToDate(item.event_date) : ''}
                                    </p>
                                    <p className="text-white text-base font-body font-medium md:mr-2 mt-3 md:mt-0">
                                        {item.start_time ? <i className="fas fa-clock mr-2"></i> : ''}
                                        {item.start_time ? convertToAmPm(item.start_time) : ''}
                                    </p>
                                </div>
                                <p className="text-white text-base font-body font-medium mt-2">
                                    {(item.address || item.city) && <i className="fas fa-map-marker-alt mr-2"></i>}
                                    {item.address}  {item.address && item.city && ', '} {item.city}
                                </p>
                                <div>
                                    <div className="block lg:flex items-center gap-6 mt-4">
                                        <button onClick={
                                            () => {
                                                window.open(`/qr/${item.url_string}`, '_blank');
                                            }
                                        } className="bg-secondary rounded-lg py-[8px] md:py-[15px] px-[8px] md:px-[12px] text-black font-body font-normal text-base 2xl:text-[18px] w-full lg:w-1/2 tracking-[0.1rem] xl:tracking-[0.3rem] uppercase">
                                            <i className="far fa-eye mr-2 xl:mr-3"></i>View Ticket
                                        </button>
                                        <button
                                            onClick={() => {
                                                props.router.navigate(`/user-tickets/${item.url_string}/share-history`)
                                            }
                                            }
                                            className="border border-secondary rounded-lg py-[8px] md:py-[15px] px-[8px] md:px-[12px] text-white font-body font-normal text-[13px] 2xl:text-[18px] w-full lg:w-1/2 tracking-[0.1rem] xl:tracking-[0.3rem] uppercase mt-4 lg:mt-0">
                                            Share history
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {activePass.length === 0 &&
                            <div className="p-[20px] md:p-[30px] mb-4 text-center">
                                <h4 className="text-white text-xl xxl:text-2xl font-body font-semibold hidden md:block">
                                    No Data Found
                                </h4>
                            </div>
                        }
                    </>
                ) : (
                    <>
                        {ticketHistory.map((item, indexTicket) => (
                            <div key={indexTicket} className="border border-[#5A5A5A] p-[20px] md:p-[30px] rounded-lg mb-4">
                                <div className="flex items-center justify-between">
                                    <h3 className="text-white text-xl lg:text-3xl font-body font-normal flex-1">
                                        {convertToDate(item.created_at)}
                                    </h3>
                                    <span className="text-gainsboro text-xl lg:text-3xl font-body font-semibold flex-none">
                                        ₹{item.pricing_details && item.pricing_details.net_amount ? item.pricing_details.net_amount : 0}
                                    </span>
                                </div>
                                <h4 className="text-white text-xl xxl:text-2xl font-body font-semibold mt-2">
                                    {item.event_name}
                                </h4>
                                <div className="block md:flex items-center mt-3 gap-6">
                                    <p className="text-white text-base font-body font-medium">
                                        {item.event_date ? <i className="fas fa-calendar mr-2"></i> : ''}
                                        {item.event_date ? convertToDate(item.event_date) : ''}
                                    </p>
                                    <p className="text-white text-base font-body font-medium mr-2 mt-3 md:mt-0">
                                        {item.start_time ? <i className="fas fa-clock mr-2"></i> : ''}
                                        {item.start_time ? convertToAmPm(item.start_time) : ''}
                                    </p>
                                </div>
                                <p className="text-white text-base font-body font-medium mt-2">
                                    {(item.address || item.city) && <i className="fas fa-map-marker-alt mr-2"></i>}
                                    {item.address}
                                    {item.address && item.city && ', '}
                                    {item.city}
                                </p>
                                <div className="block lg:flex items-center gap-6 mt-4">
                                    <button onClick={
                                        () => {
                                            window.open(`/qr/${item.url_string}`, '_blank');
                                        }
                                    } className="bg-gainsboro rounded-lg py-[8px] md:py-[15px] px-[8px] md:px-[12px] text-black font-body font-normal text-base xxl:text-[18px] w-full lg:w-1/2 tracking-[0.1rem] xl:tracking-[0.3rem] uppercase">
                                        <i className="far fa-eye mr-2 md:mr-3"></i>View Ticket
                                    </button>
                                    <button
                                        onClick={() => {
                                            props.router.navigate(`/user-tickets/${item.url_string}/share-history`)
                                        }
                                        }
                                        className="border border-gainsboro rounded-lg py-[8px] md:py-[15px] px-[8px] md:px-[12px] text-gainsboro font-body font-normal text-base xxl:text-[18px] w-full lg:w-1/2 tracking-[0.1rem] xl:tracking-[0.3rem] uppercase mt-4 lg:mt-0">
                                        Share history
                                    </button>
                                </div>
                            </div>
                        ))}
                        {ticketHistory.length === 0 &&
                            <div className="p-[20px] md:p-[30px] mb-4 text-center">
                                <h4 className="text-white text-xl xxl:text-2xl font-body font-semibold hidden md:block">
                                    No Data Found
                                </h4>
                            </div>
                        }
                    </>
                )}
            </div>
            <div className="flex items-center justify-center mt-5">
                {activeButton === 1 ? (
                    <>
                        {totalActivePass > activePass.length ? (
                            <button
                                className="bg-secondary rounded-lg py-2 px-4 text-black font-body font-normal text-base 2xl:text-[18px] tracking-[0.1rem] uppercase"
                                onClick={loadMoreActivePass}
                            >
                                Load More
                            </button>
                        )
                            : ''}
                    </>
                ) : (
                    <>
                        {totalTicketHistory > ticketHistory.length ? (
                            <button
                                className="bg-gainsboro rounded-lg py-2 px-4 text-black font-body font-normal text-base 2xl:text-[18px] tracking-[0.1rem] uppercase"
                                onClick={loadMoreTicketHistory}
                            >
                                Load More
                            </button>
                        )
                            : ''}
                    </>
                )}
            </div>
        </>
    )
}
TicketHistory.propTypes = {
    props: PropTypes.object,
}
export default TicketHistory
