import React from 'react';
import ArtistView from '../../components/Artist/View';
import { Link } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import { isEmpty } from "lodash";


const Artist = ({ artists, props }) => {



    return (
        <>
            {!isEmpty(artists) ? (
                <div className="bg-neutral pt-10 pb-24">
                    <div className="container mx-auto ">
                        <div className='flex items-center justify-between gap-4 mb-10'>
                            <h2 className='text-[22px] md:text-[40px] font-body font-normal tracking-[0.3rem] text-white uppercase'>Artist</h2>
                        </div>
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            freeMode={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,

                                },
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                            }}
                            modules={[Autoplay, FreeMode, Pagination]}
                            className="mySwiper"
                        >
                            {artists && artists.map((singleArtist, index) => (
                                <>

                                    {!isEmpty(singleArtist.artist) ? (
                                        <SwiperSlide>
                                            {/* <Link to={"/artiest/" + singleArtist.artist.slug}> */}
                                            <ArtistView singleArtist={singleArtist.artist} pageTitle="Home" props={props} key={index} />
                                            {/* </Link> */}
                                        </SwiperSlide>
                                    ) : ''}
                                </>
                            ))}

                        </Swiper>

                    </div>
                </div>
            ) : ''}
        </>
    );
}

export default Artist;
