import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import banner from '../../assets/images/sign-up.png'
import logo from '../../assets/images/small/logo-light.png'
import labelConstant from "../../constants/label"
import regex from "../../constants/regex"
import OTP from "../Common/OTP"
import Text from "../Common/Text"
const Login = ({
    validation,
    isOtpSent,
    validationOtp,
    resendOtp,
    recaptchaResponse,
    props,
}) => {

    const [timeLeft, setTimeLeft] = useState(120); // 2 minutes = 120 seconds
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            return () => clearInterval(timer);
        } else {
            setShowButton(true);
        }
    }, [timeLeft]);

    const handleResendOtp = (e) => {
        e.preventDefault();
        resendOtp(); // Call the resendOtp function
        setShowButton(false); // Hide the button
        setTimeLeft(120); // Reset the timer to 2 minutes
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };
    return (
        <div className="h-[850px] md:h-[1075px] bg-neutral bg-blend-normal flex items-center bg-no-repeat" style={{ backgroundImage: `url(${banner})` }}>
            <div className="container">
                <div className='hidden md:block'>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {isOtpSent ? (
                            <>
                                <div className='hidden md:block'>
                                    <h2 className="text-4xl md:text-[50px] text-secondary font-body font-light">Enter OTP</h2>
                                    <p className='text-sm md:text-base font-normal text-white font-body mt-5 w-[90%]'>Enter your email to receive a password reset link. Check your inbox and follow the instructions to regain access to your account.</p>
                                </div>
                                <div className='md:hidden block'>
                                    <h2 className="text-3xl text-white font-body font-light text-center mb-3">Login</h2>
                                    <p className="text-base text-white font-body font-normal text-center max-w-[82%] m-auto pb-7">Welcome to <Link to="" className='font-body font-semibold text-secondary'>MePass</Link> Please enter your
                                        Email and password for login</p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <h2 className="text-4xl md:text-5xl text-secondary font-body font-light">Welcome back to Mepass!</h2>
                                    <p className='text-sm md:text-base font-normal text-white font-body mt-5 w-[90%]'>Reconnect with your cherished moments as you log in to your account. Each login is a journey of making experiences truly your own. Let's continue creating memories together.</p>
                                </div>
                            </>
                        )}

                        <div>
                            <div className='bg-[#212121] rounded-lg'>
                                <div className='p-[25px] md:p-[50px] pb-0 md:pb-0'>
                                    <img src={logo} alt="" className='m-auto' />
                                    <div className='mt-[50px]'>
                                        <form
                                            onSubmit={e => {
                                                e.preventDefault()
                                                isOtpSent
                                                    ? validationOtp.handleSubmit()
                                                    : validation.handleSubmit()
                                            }}
                                        >

                                            {isOtpSent ? (
                                                <>
                                                    <div>
                                                        <div className='flex items-start justify-between'>
                                                            <OTP
                                                                validation={validationOtp}
                                                                name="otp"
                                                                label={labelConstant.OTP}
                                                                isRequire={true}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='text-center mt-5'>
                                                        {showButton ? (
                                                            <button
                                                                onClick={handleResendOtp}
                                                                className='border border-primary rounded-full text-white text-base font-body font-medium px-4 py-2 inline-block'>
                                                                Resend OTP
                                                            </button>
                                                        ) : (
                                                            <p className='text-white text-base font-body font-medium'>
                                                                Resend OTP in {formatTime(timeLeft)}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        disabled={validationOtp.isSubmitting ? true : false}
                                                        className="bg-primary border border-primary  text-white text-base font-normal tracking-[0.3rem] md:py-4 py-2 px-8 rounded-[5px] md:rounded-[10px] font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5"
                                                    >
                                                        {" "}
                                                        Submit OTP
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <Text
                                                        validation={validation}
                                                        name="mobile"
                                                        label="Mobile Number"
                                                        isRequire={true}
                                                        placeholder="Mobile Number"
                                                        regexCompare={regex.MOBILE}
                                                        maxLength={10}
                                                    />


                                                    <button
                                                        type="submit"
                                                        disabled={validation.isSubmitting ? true : false}
                                                        className="bg-primary border border-primary  text-white text-base font-normal tracking-[0.3rem] py-4 px-8 rounded-[10px] font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5"
                                                    >
                                                        GET OTP
                                                    </button>
                                                </>
                                            )}
                                        </form>

                                    </div>
                                    <div className='mt-5 text-center'>
                                        {props.router.location.state && props.router.location.state.is_login_page == 'Yes' ? (
                                            <p className='text-white text-lg font-normal font-body'>Don't have an account? <Link to='/sign-up' className='text-white font-bold'>Sign Up</Link></p>
                                        ) : (
                                            <p className='text-white text-lg font-normal font-body'>Don't have an account? <Link to='/sign-up' state={{ previousPage: 'Login' }} className='text-white font-bold'>Sign Up</Link></p>
                                        )}

                                    </div>
                                </div>
                                {/* <p className='relative border-b border-[#5A5A5A] pt-5'>
                                    <span className='text-gainsboro text-sm font-body font-normal bg-[#212121] p-2 absolute left-0 right-0 top-[2px] m-auto inline-block w-[31px] text-center'>or</span>
                                </p> */}
                                <div className='p-[25px] md:p-[50px] pt-0 md:pt-0'>
                                    <div className='grid grid-cols-2 gap-4 mt-7'>
                                        {/* <div className='border border-[#5A5A5A] h-[57px] w-full rounded-lg flex items-center justify-center'>
                                            <img src={google} alt="" className='m-auto' />
                                        </div>
                                        <div className='border border-[#5A5A5A] h-[57px] w-full rounded-lg flex items-center justify-center'>
                                            <img src={instagram} alt="" className='m-auto' />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ------------------------------mobile-view------------------ */}
                <div className='md:hidden block'>
                    <h2 className="text-3xl text-white font-body font-light text-center mb-3">Login</h2>
                    <p className="text-base text-white font-body font-normal text-center max-w-[82%] m-auto pb-7">Welcome to <Link to="" className='font-body font-semibold text-secondary'>MePass</Link> Please enter your
                        Email and password for login</p>
                    <div className='bg-[#212121] rounded-[10px] mt-7 px-[36px] py-[29px]'>
                        <div className='text-center'>
                            <img src={logo} alt='img' className='inline-block' />
                        </div>
                        <div className='mt-3'>
                            <form
                                onSubmit={e => {
                                    e.preventDefault()
                                    isOtpSent
                                        ? validationOtp.handleSubmit()
                                        : validation.handleSubmit()
                                }}
                            >

                                {isOtpSent ? (
                                    <>
                                        <div>
                                            <div className='flex items-start justify-between'>
                                                <OTP
                                                    validation={validationOtp}
                                                    name="otp"
                                                    label={labelConstant.OTP}
                                                    isRequire={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='text-center mt-5'>
                                            {showButton ? (
                                                <button
                                                    onClick={handleResendOtp}
                                                    className='border border-primary rounded-full text-white text-base font-body font-medium px-4 py-2 inline-block'>
                                                    Resend OTP
                                                </button>
                                            ) : (
                                                <p className='text-white text-base font-body font-medium'>
                                                    Resend OTP in {formatTime(timeLeft)}
                                                </p>
                                            )}
                                        </div>
                                        <button
                                            type="submit"
                                            disabled={validationOtp.isSubmitting ? true : false}
                                            className="bg-primary border border-primary  text-white text-base font-normal tracking-[0.3rem] md:py-4 py-2 px-8 rounded-[5px] md:rounded-[10px] font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5"
                                        >
                                            {" "}
                                            Submit OTP
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <Text
                                            validation={validation}
                                            name="mobile"
                                            label={labelConstant.MOBILE}
                                            isRequire={true}
                                            placeholder="Mobile Number"
                                            regexCompare={regex.MOBILE}
                                        />
                                        <button
                                            type="submit"
                                            disabled={validation.isSubmitting ? true : false}
                                            className="bg-primary border border-primary  text-white text-base font-normal tracking-[0.3rem] py-4 px-8 rounded-[10px] font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5"
                                        >
                                            GET OTP
                                        </button>
                                    </>
                                )}
                            </form>
                        </div>
                        <div className='mt-3 text-center'>
                            {props.router.location.state && props.router.location.state.is_purchase ? (
                                < p className='text-white text-lg font-normal font-body flex justify-center items-center whitespace-nowrap'>Don't have an account? <Link to='/sign-up' className='text-white font-bold '>Sign Up</Link></p>
                            ) : (
                                < p className='text-white text-lg font-normal font-body flex justify-center items-center whitespace-nowrap'>Don't have an account? <Link to='/sign-up' state={{ previousPage: 'Login' }} className='text-white font-bold '>Sign Up</Link></p>
                            )}
                        </div>
                    </div>
                </div>


            </div>
        </div >
    )
}
Login.propTypes = {
    validation: PropTypes.object,
    recaptchaRef: PropTypes.object,
    isOtpSent: PropTypes.bool,
    validationOtp: PropTypes.object,
    resendOtp: PropTypes.func,
}
export default Login
