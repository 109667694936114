import React, { useState, useRef, useEffect } from 'react';
import { Link, NavLink } from "react-router-dom";
import withRouter from "../Common/withRouter";
import logo from "../../assets/images/mepass-logo.svg";
import notification from "../../assets/images/small/notification.png";
import user from "../../assets/images/small/user.png";
import icon from "../../assets/images/small/location-user.svg";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from 'react-redux';
import { clearData, getData } from '../../store/AppMaster/actions';
import api from "../../constants/api";
import SelectCityModal from './SelectCityModal';
import SidebarMenu from './SidebarMenu';
import ProfileMenu from './ProfileMenu';

const Header = (props) => {
  const authUserDetails = JSON.parse(localStorage.getItem("publicMePassUserDetails"));
  const [cityList, setCityList] = useState([]);
  const [isApiCallHeader, setIsApiCallHeader] = useState(0);
  const [isUpdateCityData, setIsUpdateCityData] = useState(false);
  const [activeItem, setActiveItem] = useState('/home');
  const fullUrl = window.location.href
  const appUrl = fullUrl.split("/")
  const lastSegment = appUrl[appUrl.length - 1]
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));

  const [sidebarArray, setSidebarArray] = useState([]);

  useEffect(() => {
    if (isApiCallHeader === 0) {
      getCityCategory(props.router.params.city_name);
      setIsApiCallHeader(1);
    }
  }, [isApiCallHeader]);

  const getCityCategory = (city) => {
    setIsUpdateCityData(true);
    setSidebarArray([]);
    setCityList([]);
    const requestData = {
      city: city
    };
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.cmsPageDropdown));
  };

  if (!isEmpty(data) && !isEmpty(data.city_list) && isUpdateCityData) {
    setCityList(data.city_list);
    const categoryArray = [];
    sidebarArray.push(
      { name: "Home", link: "/home", submenu: [] },
    );
    let link = '/events';
    if (props.router.params.category_name && props.router.params.city_name) {
      link = `/city/${props.router.params.city_name}/category/${props.router.params.category_name}/events`;
    } else if (props.router.params.category_name) {
      link = `/category/${props.router.params.category_name}/events`;
    } else if (props.router.params.city_name) {
      link = `/city/${props.router.params.city_name}/events`;
    }
    sidebarArray.push({ name: "Events", link: link, submenu: [] });

    if (!isEmpty(data.categoriesWithCity)) {
      data.categoriesWithCity.map((item) => {
        let link = '';
        if (props.router.params.city_name) {
          link = `/city/${props.router.params.city_name}/category/${item.slug}`;
        } else {
          link = `/category/${item.slug}`;
        }
        item.link = link;
        categoryArray.push(item);
      });
    }
    if (!isEmpty(data.categoriesWithoutCity)) {
      data.categoriesWithoutCity.map((item) => {
        let link = '';
        link = `/category/${item.slug}`;
        item.link = link;
        categoryArray.push(item);
      });
    }

    if (!isEmpty(categoryArray)) {
      categoryArray.map((item, itemIndex) => {
        let categoryName = item.name.charAt(0).toUpperCase() + item.name.slice(1);
        if (itemIndex < 3) {
          sidebarArray.push({ name: categoryName, link: item.link, submenu: [] });
        } else if (itemIndex == 3) {
          sidebarArray.push({ name: 'More', link: '#', submenu: [{ name: categoryName, link: item.link }] });
        } else if (itemIndex > 3) {
          sidebarArray[5] && sidebarArray[5].submenu && sidebarArray[5].submenu.push({ name: categoryName, link: item.link });
        }
      });
    }
    setIsUpdateCityData(false);
  }

  const [sidebarToggle, setSidebarToggle] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleNavLinkClick = () => {
    setSidebarToggle(false); // Close sidebar when a link is clicked
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isShowCityModal, setShowCityModal] = useState(false);
  const [isDropdownResOpen, setIsDropdownResOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRefRes = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleCityModal = () => {
    setShowCityModal(!isShowCityModal);
  };

  // Toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Close dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const handleClickOutsideRes = (event) => {
    if (dropdownRefRes.current && !dropdownRefRes.current.contains(event.target)) {
      setIsDropdownResOpen(false);
    }
  };
  const handleOnSelect = (item) => {
    setSelectedItem(item.city); // Set the selected item as active
    setIsDropdownOpen(false); // Close the dropdown
    setIsDropdownResOpen(false); // Close the dropdown
    getCityCategory(item.city);
    setShowCityModal(false);
    if (lastSegment === 'events') {
      let link = '/events';
      if (props.router.params.category_name) {
        link = `/city/${item.city}/category/${props.router.params.category_name}/events`;
      } else {
        link = `/city/${item.city}/events`;
      }
      props.router.navigate(link);
    } else {
      props.router.navigate(`/city/${item.city}`);
    }

  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleClickOutsideRes);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleClickOutsideRes);
    };
  }, []);
  // State to track which submenu is open
  const [openSubmenu, setOpenSubmenu] = useState(null);

  // Function to toggle submenu visibility
  const toggleSubmenu = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  // Function to set active menu or submenu
  const handleActiveItem = (path) => {
    setActiveItem(path);
  };

  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);

  // Function to toggle submenu
  const toggleSubmenuIndex = (index) => {
    setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
  };

  return (
    <React.Fragment>
      <div className="relative z-10">
        <nav className="bg-gradient-to-t from-[#00000000] to-[#00000099] absolute top-0 w-full">
          <div className="container mx-auto py-[15px]">
            <div className="relative flex h-16 items-center justify-between gap-3 md:gap-14">
              <div className="flex items-center gap-0 md:gap-16">
                <div className="w-full md:w-[163.63px]">
                  <Link to="/home">
                    <img className="w-auto" src={logo} alt="Your Company" />
                  </Link>
                </div>
                <div className="relative hidden md:block">
                  <div className="w-[150px] md:w-[210px] border border-white rounded-full px-2 py-1.5 md:py-2 md:px-3 relative" ref={dropdownRef}>
                    <div className="flex items-center cursor-pointer" onClick={handleCityModal}>
                      <img src={icon} alt="img" className="inline-block mr-2" />
                      <p className="text-sm md:text-lg text-white font-normal">
                        {props.router.params.city_name ? props.router.params.city_name : 'Select your City'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative block md:hidden">
                <div className="w-[150px] border border-white rounded-full px-2 py-1.5 relative" ref={dropdownRef}>
                  <div className="flex items-center cursor-pointer" onClick={handleCityModal}>
                    <img src={icon} alt="img" className="inline-block mr-2" />
                    <p className="text-sm md:text-lg text-white font-normal">
                      {props.router.params.city_name ? props.router.params.city_name : 'Select your City'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex lg:flex-auto items-center justify-end xl:justify-between">
                <div className="hidden xl:block">
                  <SidebarMenu sidebarArray={sidebarArray}
                    toggleSubmenu={toggleSubmenu}
                    handleActiveItem={handleActiveItem}
                    activeItem={activeItem}
                    openSubmenu={openSubmenu}
                    isResponsive={false} />
                </div>
                <div>
                  <div className="flex items-center">
                    <ProfileMenu notification={notification} authUserDetails={authUserDetails} user={user} />
                    <SidebarMenu sidebarArray={sidebarArray}
                      toggleSubmenu={toggleSubmenu}
                      handleActiveItem={handleActiveItem}
                      activeItem={activeItem}
                      openSubmenu={openSubmenu}
                      isResponsive={true}
                      handleSidebarToggle={handleSidebarToggle}
                      sidebarToggle={sidebarToggle}
                      handleNavLinkClick={handleNavLinkClick}
                      toggleSubmenuIndex={toggleSubmenuIndex}
                      openSubmenuIndex={openSubmenuIndex} />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* modal city */}
      {isShowCityModal ? (
        <SelectCityModal isShowCityModal={isShowCityModal}
          setShowCityModal={setShowCityModal}
          cityList={cityList}
          handleOnSelect={handleOnSelect}
          props={props} />
      ) : null}

    </React.Fragment>
  );
};

export default withRouter(Header);
