import React, { useEffect } from 'react';
import banner4 from '../../assets/images/terms-condition-banner.png';
import SeoDetails from '../../seo/SeoDetails';
function TermsCondition() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <SeoDetails title="mepass | Terms & Condition"
                description="mepass | Terms & Condition"
            />
            <div className="w-full h-full md:h-[400px]  bg-cover pt-28 bg-right md:pt-[135px] pb-4 md:pb-20" style={{ backgroundImage: `url(${banner4})` }}>
                <div className="container ">

                    <h2 className='text-white text-[38px] md:text-[64px] font-body font-semibold h-[240px] flex items-end'>Terms & Condition</h2>
                </div>
            </div>


            {/* -------------------------------terms-condition-start------------------------- */}
            <div className='hidden md:block'>
                <section className='bg-neutral py-14'>
                    <div className="container">
                        <h3 className='text-[30px] font-body font-normal text-[#FFD254] mb-2'>User Terms & Conditions</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>
                            Welcome to Mepass, your ultimate destination for discovering and booking tickets to a wide range of events. These User Terms & Conditions govern your use of the Mepass platform. By accessing or using our platform, you agree to abide by these terms.
                        </p>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>1. Account Creation and Usage</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Users are required to create an account on Mepass to access and utilise the platform's features.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>User accounts must be created with accurate and up-to-date information.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Users may link their social media accounts for seamless login and event discovery.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>2. Event-Specific Terms & Conditions</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Users acknowledge and agree that each event listed on Mepass may have its own specific Terms & Conditions.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Users are responsible for reviewing and accepting the Terms & Conditions associated with each event they wish to attend.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>3. Ticket Purchasing and Convenience Charges</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Users may purchase tickets for events listed on Mepass through the platform's ecommerce functionality.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Some ticket purchases may be subject to convenience charges or internet handling fees, which will be clearly communicated during the booking process.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>4. Refund Policy</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Refunds for ticket purchases are subject to the refund policy of the respective event organiser or merchant.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>In the event of event cancellation, Mepass will facilitate refunds according to the event organiser's refund policy.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Individual refund requests, subject to organiser approval, as well as tickets purchased directly from event merchants and sales teams where payments are not processed through the Mepass platform, will be handled by the respective merchant.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>5. Sharing of Tickets</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Users may share purchased tickets with others through the platform's designated sharing feature.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Mepass is not responsible for any miscommunication or missharing related to shared tickets.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>6. Ticket History and Pass Management</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Users can access their ticket purchase history and manage their active and past passes through the platform.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>7. Entry Requirements and Identification</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Users must present their tickets along with any required identification documents for event entry. Applicable in certain events only.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Users are advised to carry physical or digital copies of their tickets, especially in areas with limited network connectivity.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>8. Copyright and Intellectual Property</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>All information, content, and branding on the Mepass platform are copyrighted and trademarked by Mepass.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>9. Dispute Resolution</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Any disputes or claims arising out of or relating to these User Terms & Conditions will be resolved through arbitration in Ahmedabad, India.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>10. Modification and Updates</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Mepass reserves the right to modify or update these User Terms & Conditions at any time without prior notice.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>11. Contact Information</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>For questions or concerns regarding these User Terms & Conditions, users may contact Mepass at <a href="https://mepass.in/contact-us" className='text-[#FFD254]'>mepass.in/contact-us</a>.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>12. Privacy Policy</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>We have managed our user privacy policy and data usage details on our page <a href="http://mepass.in/privacy-policy" className='text-[#FFD254]'>mepass.in/privacy-policy</a>.</li>
                        </ul>
                        <h3 className='text-[30px] font-body font-normal text-[#FFD254] mt-8 mb-2'>Merchant Terms & Conditions</h3>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>1. Introduction</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>
                            Welcome to Mepass Merchant Services! These Merchant Terms & Conditions govern your use of the Mepass platform as a merchant. By accessing or using our platform, you agree to abide by these terms.
                        </p>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>2. Merchant Responsibilities</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Merchants are required to create an account on Mepass with accurate and up-to-date information. All information provided by merchants will be used in accordance with our Privacy Policy.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>By providing information to Mepass, merchants consent to the internal use of their data for platform-related purposes only.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Merchants are solely responsible for utilising Mepass software and ecommerce platform for event hosting and management.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>3. Event Hosting</h3>
                        <ul

                            className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Merchants must provide authentic and relevant details for each event hosted on the Mepass platform, ensuring compliance with all terms and conditions.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Any changes made to event details, pricing, or features during the event are the sole responsibility of the merchant. Mepass will provide support and assistance as per merchant guidelines.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Ticket pricing and offers are determined solely by the merchant. Mepass is not liable for any discounts or offers provided by merchants.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>4. Promotion and Sales</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Mepass reserves the right to promote events on its platform based on performance and special charges for featuring events.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Merchants are responsible for managing event ticket sales through both online and manual channels. Mepass will provide support, but data management and sales history are the merchant's responsibility.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Access to the Mepass merchant account should be limited to authorised personnel only.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>5. Financial Transactions</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Mepass charges convenience fees, including internet handling fees, as mentioned in the pricing plans.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Merchants may purchase additional services and products listed on the Mepass marketplace as per their requirements.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>All services and charges are subject to government taxes as per applicable laws.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Mepass deducts convenience charges and applicable GST on ticketing sales amounts. Additional GST and TDS charges may apply.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Payments to Mepass for services are non-refundable, and Mepass reserves the right to deduct pending payments from future ticketing revenue.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>6. Data Usage and Protection</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Data generated from ecommerce and manual ticketing sales is owned by Mepass. Merchants may use their own database for marketing purposes through Mepass.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Merchants are prohibited from extracting data from the Mepass software and selling it to third parties. Data usage is limited to marketing purposes only.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Mepass reserves the right to change data visibility and availability at any time.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>7. Intellectual Property</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Mepass software and ecommerce platform credentials are reserved for event hosting and utilisation purposes. Copying or sharing the software without authorization is strictly prohibited.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Merchants may use Mepass and its logo for event ticketing sales promotion and marketing purposes with written approval.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>8. Termination and Refunds</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Mepass reserves the right to terminate a merchant's account and discontinue services in case of violation of these terms and conditions.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Merchants are liable to pay refunds in the event of event cancellation or postponement due to any circumstances.</li>
                        </ul>
                        <h3 className='text-white text-lg font-body font-normal mt-4'>9. Updates to the Terms and Conditions</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Mepass may modify these terms and conditions at any time. Merchants are encouraged to review the terms periodically for updates.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Feedback from merchants regarding these terms and conditions is welcome.</li>
                        </ul>
                    </div>
                </section>
            </div>

            {/* -------------------------------terms-condition-end------------------------- */}

            {/* --------------------------------mobile-view-start--------------------------------- */}
            <div className='md:hidden block'>
                <section className='bg-neutral py-14'>
                    <div className="container">
                        <h2 className='text-lg font-body font-normal text-white'>Terms & Conditions</h2>
                        <h3 className='text-lg font-body font-normal text-white mt-6'>1. Introduction</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mt-4'>
                            Welcome to Mepass, your ultimate destination for discovering and booking tickets to a wide range of events. These User Terms & Conditions govern your use of the Mepass platform. By accessing or using our platform, you agree to abide by these terms.
                        </p>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>2. Account Creation and Usage</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Users are required to create an account on Mepass to access and utilise the platform's features.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>User accounts must be created with accurate and up-to-date information.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Users may link their social media accounts for seamless login and event discovery.</li>
                        </ul>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>3. Event-Specific Terms & Conditions</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Users acknowledge and agree that each event listed on Mepass may have its own specific Terms & Conditions.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Users are responsible for reviewing and accepting the Terms & Conditions associated with each event they wish to attend.</li>
                        </ul>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>4. Ticket Purchasing and Convenience Charges</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Users may purchase tickets for events listed on Mepass through the platform's ecommerce functionality.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Some ticket purchases may be subject to convenience charges or internet handling fees, which will be clearly communicated during the booking process.</li>
                        </ul>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>5. Refund Policy</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Refunds for ticket purchases are subject to the refund policy of the respective event organiser or merchant.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>In the event of event cancellation, Mepass will facilitate refunds according to the event organiser's refund policy.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Individual refund requests, subject to organiser approval

                                , as well as tickets purchased directly from event merchants and sales teams where payments are not processed through the Mepass platform, will be handled by the respective merchant.</li>
                        </ul>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>6. Sharing of Tickets</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Users may share purchased tickets with others through the platform's designated sharing feature.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Mepass is not responsible for any miscommunication or missharing related to shared tickets.</li>
                        </ul>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>7. Ticket History and Pass Management</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Users can access their ticket purchase history and manage their active and past passes through the platform.</li>
                        </ul>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>8. Entry Requirements and Identification</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Users must present their tickets along with any required identification documents for event entry. Applicable in certain events only.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Users are advised to carry physical or digital copies of their tickets, especially in areas with limited network connectivity.</li>
                        </ul>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>9. Copyright and Intellectual Property</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>All information, content, and branding on the Mepass platform are copyrighted and trademarked by Mepass.</li>
                        </ul>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>10. Dispute Resolution</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Any disputes or claims arising out of or relating to these User Terms & Conditions will be resolved through arbitration in Ahmedabad, India.</li>
                        </ul>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>11. Modification and Updates</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>Mepass reserves the right to modify or update these User Terms & Conditions at any time without prior notice.</li>
                        </ul>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>12. Contact Information</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>For questions or concerns regarding these User Terms & Conditions, users may contact Mepass at <a href="http://mepass.in/contact-us" className='text-[#FFD254]'>mepass.in/contact-us</a>.</li>
                        </ul>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>13. Privacy Policy</h3>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>We have managed our user privacy policy and data usage details on our page <a href="http://mepass.in/privacy-policy" className='text-[#FFD254]'>mepass.in/privacy-policy</a>.</li>
                        </ul>
                    </div>
                </section>
            </div>
            {/* --------------------------------mobile-view-end--------------------------------- */}



        </>
    );
}

export default TermsCondition;
