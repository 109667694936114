import React, { useEffect } from 'react';
import banner3 from '../../assets/images/privacy-policy-banner.png';
import SeoDetails from '../../seo/SeoDetails';
function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <SeoDetails title="mepass | Privacy Policy"
                description="mepass | Privacy Policy"
            />
            <div className="w-full h-full md:h-[400px] bg-cover pt-28 bg-right md:pt-[135px] pb-4 md:pb-20" style={{ backgroundImage: `url(${banner3})` }}>
                <div className="container ">

                    <h2 className='text-white text-[38px] md:text-[64px] font-body font-semibold h-[240px] flex items-end'>Privacy Policy</h2>
                </div>
            </div>

            {/* -------------------------------terms-condition-start------------------------- */}
            <div className='hidden md:block'>
                <section className='bg-neutral py-14'>
                    <div className="container">
                        <h3 className='text-[30px] font-body font-normal text-[#FFD254] mb-2'>User Privacy Policy</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>To protect the privacy of individuals ("You") who use the MEPass site and related services ("Services"), MEPass("Event Company", "We" or "Us") is committed to the following policy. This Privacy Policy explains how we utilise Personal Information (defined below) and non-personal information. Before using our Services, read our Privacy Policy, which covers the processing of personal and non-personal information. You cannot use our Services if you disagree with our Privacy Policy. "Personal Information" is information that identifies You. We may gather "non-personal information" that cannot identify a person. Personal Information can be deidentified to create non-personal information. We gather, utilise, and combine both sorts of information. Dealing with Private Data.</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mt-2'>MEPass only collects essential Personal Information. MEPass will use Your Data to schedule events and encourage participant networking using its platform and services.
                            MEPass would use Your Personal Information to complete your service requests, send You alerts, invoices, and other communications, and tell You about promotions and other news about its services. MEPass may process Personal Information according to applicable legislation. MEPass staff must keep Personal Information secret.
                            Processing legality.</p>
                        <p className='text-white text-lg font-body font-normal mt-2'>As mentioned below, the legal basis for processing Personal Information relies on the processing activities</p>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>GDPR art. 6(1)(a) allows personal data processing for specific purposes.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Contracting. GDPR art. 6(1)(b) allows handling Personal Information to fulfill a contract with the data subject, such as providing services. Our product and service inquiries require pre-contractual processing.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Duty. GDPR art. 6(1)(c) allows Personal Information processing for legal obligations like taxes.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Essentials. GDPR art. 6(1)(d) allows medical professionals to use Personal Information to protect a visitor's essential interests.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Rights. GDPR art. 6(1)(f) is the constitutional basis for processing actions necessary for Our legitimate interests, such as marketing, but not covered by any of the above legal justifications.</li>
                        </ul>
                        <p className='text-white text-lg font-body font-normal mt-2'>Data Collection and Information Sourcing</p>
                        <p className='text-[#DCDCDC] text-lg font-body font-normal mt-2'>We collect your data when you use our Services. Our website allows newsletter signup.</p>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>MEPass collects non-personal Service usage statistics.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>MEPass retains Personal Information collected under this Privacy Notice as long as necessary to achieve its aims unless required or allowed by law. XXXXXstores Personal Data until it is needed for a trade or contract, based on the legal basis for processing.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>MEPass will delete or anonymize Personal Information that is no longer needed for this Privacy Policy.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Third-party data and analytics providers increase Services Event Company India.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Disclosure and Hosting</li>
                        </ul>
                        <p className='text-[#DCDCDC] text-lg font-body font-normal mt-2'>MEPass will not disclose stored Personal Information for direct electronic marketing (email, text messaging) without your approval. Third-party service partners help us manage and use saved data via a technical interface to execute, improve, and maintain the Services. We use third-party email, credit cards, information, and business intelligence. MEPass may share Personal Information with partners for services. MEPass is not liable for third-party activity. In rare situations, third parties may receive Private Details to execute the Terms of Service, investigate suspected infringing use of the Services, and maintain Service safety. If MEPass merges, sells, finances, or acquires, it may release your information. Advertisers, broadcasters, business partners, and others may get aggregate or anonymized data. Confidentiality</p>
                        <p className='text-[#DCDCDC] text-lg font-body font-normal mt-2'>External service providers' registrations will be password-protected for MEPass personnel and authorized partners. Industry-standard, technological, and organizational measures protect the register. We try to store platform data in a secure operational framework that's not public, but we can't guarantee its complete secrecy on our servers. Individuals' Rights and Contact Details Regarding Their Data.</p>
                        <p className='text-white text-lg font-body font-normal mt-2'>Accessibility, correction, and objections rights</p>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>If you have any questions about the Personal Data we have gathered and processed about you, or the uses to which we put such information, please contact us. You may request that we update or delete any of your personally identifiable information that is inaccurate, incomplete, unreliable, or no longer needed by contacting us.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>The right to erasure and processing limitations</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>If you'd want us to remove your information from our databases, just let us know. Any time you make such a request, we will erase your information unless there is a good reason not to. While we will make every effort to remove all traces of the data from our systems after deletion, this may take some time. The copies must be removed as soon as practicable. Access to one’s portable data</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>If you give us personally identifiable information, you can request to get it in a structured, generally used format.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Modifications to the Confidentiality Agreement.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>MEPass may, at any moment and in its sole discretion, amend this Privacy Policy. There will be an updated version of this page posted on our website if any changes are made.</li>
                        </ul>
                        <h3 className='text-[30px] font-body font-normal text-[#FFD254] mt-8 mb-2'>Merchant Privacy Policy</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>To protect the privacy of individuals ("You") who use the MEPass site and related services ("Services"), MEPass("Event Company", "We" or "Us") is committed to the following policy. This Privacy Policy explains how we utilise Personal Information (defined below) and non-personal information. Before using our Services, read our Privacy Policy, which covers the processing of personal and non-personal information. You cannot use our Services if you disagree with our Privacy Policy. "Personal Information" is information that identifies You. We may gather "non-personal information" that cannot identify a person. Personal Information can be deidentified to create non-personal information. We gather, utilise, and combine both sorts of information. Dealing with Private Data.</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mt-2'>MEPass only collects essential Personal Information. MEPass will use Your Data to schedule events and encourage participant networking using its platform and services.
                            MEPass would use Your Personal Information to complete your service requests, send You alerts, invoices, and other communications, and tell You about promotions and other news about its services. MEPass may process Personal Information according to applicable legislation. MEPass staff must keep Personal Information secret.
                            Processing legality.</p>
                        <p className='text-white text-lg font-body font-normal mt-2'>As mentioned below, the legal basis for processing Personal Information relies on the processing activities</p>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>GDPR art. 6(1)(a) allows personal data processing for specific purposes.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Contracting. GDPR art. 6(1)(b) allows handling Personal Information to fulfill a contract with the data subject, such as providing services. Our product and service inquiries require pre-contractual processing.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Duty. GDPR art. 6(1)(c) allows Personal Information processing for legal obligations like taxes.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Essentials. GDPR art. 6(1)(d) allows medical professionals to use Personal Information to protect a visitor's essential interests.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Rights. GDPR art. 6(1)(f) is the constitutional basis for processing actions necessary for Our legitimate interests, such as marketing, but not covered by any of the above legal justifications.</li>
                        </ul>
                        <p className='text-white text-lg font-body font-normal mt-2'>Data Collection and Information Sourcing</p>
                        <p className='text-[#DCDCDC] text-lg font-body font-normal mt-2'>We collect your data when you use our Services. Our website allows newsletter signup.</p>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>MEPass collects non-personal Service usage statistics.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>MEPass retains Personal Information collected under this Privacy Notice as long as necessary to achieve its aims unless required or allowed by law. XXXXXstores Personal Data until it is needed for a trade or contract, based on the legal basis for processing.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>MEPass will delete or anonymize Personal Information that is no longer needed for this Privacy Policy.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Third-party data and analytics providers increase Services Event Company India.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Disclosure and Hosting</li>
                        </ul>
                        <p className='text-[#DCDCDC] text-lg font-body font-normal mt-2'>MEPass will not disclose stored Personal Information for direct electronic marketing (email, text messaging) without your approval. Third-party service partners help us manage and use saved data via a technical interface to execute, improve, and maintain the Services. We use third-party email, credit cards, information, and business intelligence. MEPass may share Personal Information with partners for services. MEPass is not liable for third-party activity. In rare situations, third parties may receive Private Details to execute the Terms of Service, investigate suspected infringing use of the Services, and maintain Service safety. If MEPass merges, sells, finances, or acquires, it may release your information. Advertisers, broadcasters, business partners, and others may get aggregate or anonymized data. Confidentiality</p>
                        <p className='text-[#DCDCDC] text-lg font-body font-normal mt-2'>External service providers' registrations will be password-protected for MEPass personnel and authorized partners. Industry-standard, technological, and organizational measures protect the register. We try to store platform data in a secure operational framework that's not public, but we can't guarantee its complete secrecy on our servers. Individuals' Rights and Contact Details Regarding Their Data.</p>
                        <p className='text-white text-lg font-body font-normal mt-2'>Accessibility, correction, and objections rights</p>
                        <ul className="list-disc mt-2 ml-6">
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-2'>If you have any questions about the Personal Data we have gathered and processed about you, or the uses to which we put such information, please contact us. You may request that we update or delete any of your personally identifiable information that is inaccurate, incomplete, unreliable, or no longer needed by contacting us.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>The right to erasure and processing limitations</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>If you'd want us to remove your information from our databases, just let us know. Any time you make such a request, we will erase your information unless there is a good reason not to. While we will make every effort to remove all traces of the data from our systems after deletion, this may take some time. The copies must be removed as soon as practicable. Access to one’s portable data</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>If you give us personally identifiable information, you can request to get it in a structured, generally used format.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>Modifications to the Confidentiality Agreement.</li>
                            <li className='text-[#DCDCDC] text-base font-body font-normal mt-1'>MEPass may, at any moment and in its sole discretion, amend this Privacy Policy. There will be an updated version of this page posted on our website if any changes are made.</li>
                        </ul>
                    </div>
                </section>
            </div>

            {/* -------------------------------terms-condition-end------------------------- */}

            {/* --------------------------------mobile-view-start--------------------------------- */}
            <div className='md:hidden block'>
                <section className='bg-neutral py-14'>
                    <div className="container">
                        <h3 className='text-lg font-body font-normal text-white'>Privacy Policy</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mt-4'>Welcome to Mepass! We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you use our platform to purchase event tickets.</p>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>Information Collected </h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mt-3'>We collect the following types of personal information from users:</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Mobile Number</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Phone Number</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Email Address</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Name</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Photo (optional)</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Date of Birth (DOB)</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- ID Proof (if required for specific events)</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Marital Status (optional)</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal my-4'>Additionally, we may collect demographic information and information collected automatically through cookies and similar technologies to enhance your experience and personalize event recommendations.</p>
                        <h3 className='text-lg font-body font-normal text-white'>Use of Information</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mt-3'>We collect and use your data to:</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Generate tickets associated with your personal information for event attendance.</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Tailor event recommendations based on your interests and preferences.</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Manage your purchase history, invoices, and ticketing history for past and future events.</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Personalize your experience on our platform and improve our services.</p>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>Sharing of Information</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mt-3'>We do not share your personal information with third-party companies for any commercial purposes. Your data may be shared with event organizers only when necessary for event entry, and may be used for marketing or notifying you about future events by Mepass.</p>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>Data Security</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mt-3'>We implement industry-standard security measures to protect your personal information from unauthorised access, disclosure, alteration, or destruction.</p>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>User Right</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Access and modify your personal information linked to your account.</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Delete your account and associated data, subject to any legal or contractual obligations.</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Opt out of receiving marketing communications from Mepass.</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Manage your preferences for cookies and tracking technologies in the settings of our application or website.</p>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>Data Security</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mt-3'>We implement industry-standard security measures to protect your personal information from unauthorised access, disclosure, alteration, or destruction.</p>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>Technical Details Usage</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>We may collect technical information about your device and how you interact with our platform, including</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mt-4'>- Device type</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Operating system</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- Browser type</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>- IP address</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mb-4'>- Usage patterns</p>
                        <p className='text-[#DCDCDC] text-base font-body font-normal'>This information is used for analyzing trends, administering the platform, and improving our services.</p>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>Marketing Communications</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mt-3'>ou can manage your communication preferences and opt out of receiving marketing communications from Mepass at any time.</p>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>Updates to the Policy</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mt-3'>We may update this Privacy Policy from time to time, and any changes will be posted on this page. We encourage you to review this policy periodically for any updates.</p>
                        <h3 className='text-lg font-body font-normal text-white mt-4'>Contact Us</h3>
                        <p className='text-[#DCDCDC] text-base font-body font-normal mt-3'>If you have any questions or concerns about our privacy practices, please contact us at support@mepass.co.in.</p>
                    </div>
                </section>
            </div>
            {/* --------------------------------mobile-view-end--------------------------------- */}



        </>
    );
}

export default PrivacyPolicy;
