
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { isEmpty } from "lodash";

const ProfileMenu = ({ notification, authUserDetails, user }) => {

    return (
        <>
            {localStorage.getItem("publicMePassUser") ? (
                <>
                    {/* <Link to="/notification">
                        <img className="w-auto mr-4" src={notification} alt="Your Company" />
                    </Link> */}
                    <Link to="/user-profile">
                        {!isEmpty(authUserDetails.profile_image_path) ? (
                            <img
                                src={authUserDetails.profile_image_path || logo}
                                className="h-10 w-10 md:h-12 md:w-12 rounded-full"
                                alt="Profile"
                            />
                        ) : (
                            <>
                                {!isEmpty(authUserDetails.name) ? (
                                    <div className="flex items-center justify-center h-12 w-12 bg-[#FFDEDE] rounded-full text-primary">
                                        {authUserDetails.name.charAt(0)}
                                    </div>
                                ) : (
                                    <img src={user} className="h-10 w-10 rounded-full" alt="Default User" />
                                )}
                            </>
                        )}
                    </Link>
                </>
            ) : (
                <Link to="/login" state={{ is_login_page: 'Yes' }}>
                    <span className="text-white text-[16px] font-normal font-body">Sign In</span>
                </Link>
            )}


        </>
    );
};

export default ProfileMenu;
