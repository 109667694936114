import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { convertToAmPm, convertToDate } from "../../common/commonFunction";
import api from "../../constants/api";
import { getData } from "../../store/AppMaster/actions";

const TicketHistoryDetails = ({
    props,
    isApiCall,
    setIsApiCall,
}) => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => ({
        data: state.AppMaster.data,
    }));

    const [isUpdateHistoryDetailData, setIsUpdateHistoryDetailData] = useState(false);
    const [historyDetail, setHistoryDetail] = useState([]);
    const [totalHistoryDetail, setTotalHistoryDetail] = useState(0);
    const [requestHistoryDetailData, setRequestHistoryDetailData] = useState({
        start: 0,
        limit: 10,
        url_string: props.router.params.uuid,
    });

    useEffect(() => {
        if (isApiCall === 0) {
            setHistoryDetail([]);
            setTotalHistoryDetail(0);
            getHistoryDetail();
            setIsApiCall(1);
        }
    }, [isApiCall, dispatch, props.router.navigate]);

    const getHistoryDetail = () => {
        setIsUpdateHistoryDetailData(true);
        dispatch(getData(requestHistoryDetailData, props.router.navigate, api.getTicketHistoryDetails));
    }

    if (!isEmpty(data) && !isEmpty(data.shareHistoryList) && isUpdateHistoryDetailData) {
        setHistoryDetail(prevPass => [...prevPass, ...data.shareHistoryList]);
        setTotalHistoryDetail(data.filter_count);
        setRequestHistoryDetailData({
            start: requestHistoryDetailData.start + 10,
            limit: 10,
            url_string: props.router.params.uuid,
        });
        setIsUpdateHistoryDetailData(false);
    }

    const loadMoreHistoryDetail = () => {
        getHistoryDetail();
    }

    return (
        <>
            <h2 className="text-white text-2xl font-body font-light uppercase tracking-[0.2rem]">
                Ticket History
            </h2>

            <div className="bg-[#1B1B1B] p-[25px] md:p-[50px] rounded-lg mt-5">
                {historyDetail.map((item, index) => (
                    <div key={index} className="border border-[#5A5A5A] p-[20px] md:p-[30px] rounded-lg mb-4">
                        <div className="flex items-center justify-between">
                            <h3 className="text-secondary md:text-white text-xl lg:text-3xl font-body font-normal flex-1">
                                {item.created_at ? convertToDate(item.created_at) : ''}
                            </h3>
                        </div>
                        <h4 className="text-white text-xl font-body font-semibold mt-2">
                            {item.event_name}
                        </h4>
                        {item.user_mobile &&
                            <h4 className="text-white text-xl font-body font-semibold mt-2">
                                {item.user_mobile}
                            </h4>
                        }
                        <div className="block md:flex items-center mt-3 gap-6">
                            <p className="text-white text-base font-body font-medium">
                                {item.event_date ? <i className="fas fa-calendar mr-2"></i> : ''}
                                {item.event_date ? convertToDate(item.event_date) : ''}
                            </p>
                            <p className="text-white text-base font-body font-medium md:mr-2 mt-3 md:mt-0">
                                {item.start_time ? <i className="fas fa-clock mr-2"></i> : ''}
                                {item.start_time ? convertToAmPm(item.start_time) : ''}
                            </p>
                        </div>
                        <p className="text-white text-base font-body font-medium mt-2">
                            {(item.address || item.city) && <i className="fas fa-map-marker-alt mr-2"></i>}
                            {item.address}  {item.address && item.city && ', '} {item.city}
                        </p>
                        {/* <div>
                            <div className="block lg:flex items-center gap-6 mt-4">
                                <button onClick={
                                    () => {
                                        window.open(`/qr/${item.url_string}`, '_blank');
                                    }
                                } className="bg-secondary rounded-lg py-[8px] md:py-[15px] px-[8px] md:px-[12px] text-black font-body font-normal text-base 2xl:text-[18px] w-full lg:w-1/2 tracking-[0.1rem] xl:tracking-[0.3rem] uppercase">
                                    <i className="far fa-eye mr-2 xl:mr-3"></i>View Ticket
                                </button>
                            </div>
                        </div> */}
                    </div>
                ))}
                {historyDetail.length === 0 &&
                    <div className="p-[20px] md:p-[30px] mb-4 text-center">
                        <h4 className="text-white text-xl xxl:text-2xl font-body font-semibold hidden md:block">
                            No Data Found
                        </h4>
                    </div>
                }
            </div>
            <div className="flex items-center justify-center mt-5">
                {totalHistoryDetail > historyDetail.length ? (
                    <button
                        className="bg-secondary rounded-lg py-2 px-4 text-black font-body font-normal text-base 2xl:text-[18px] tracking-[0.1rem] uppercase"
                        onClick={loadMoreHistoryDetail}
                    >
                        Load More
                    </button>
                )
                    : ''}
            </div>
        </>
    )
}
TicketHistoryDetails.propTypes = {
    props: PropTypes.object,
}
export default TicketHistoryDetails
