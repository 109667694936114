import React from "react";
import icon from "../../assets/images/small/info-light.png";
import icon2 from "../../assets/images/small/phone-pay.png";

const PaymentOption = ({ event, paymentDetails, passList, processedToPay, setIsProcessedToPay }) => {
  const gstAmount = passList.reduce((total, pass) => total +
    pass.quantity > 0 ? pass.igst : 0, 0);
  const gstAmountQty = gstAmount * passList.reduce((total, pass) => total +
    pass.quantity, 0)
  return (
    <div className="bg-neutral pt-28 md:pt-40 pb-20">
      <div className="container">
        {/* ----------------card-start--------------- */}
        <div className="hidden md:block">

          <div className="flex items-center justify-between">
            <h2 className="text-3xl md:text-5xl text-white font-body font-medium">
              {event.name ? event.name : ''}
            </h2>
            <button
              onClick={
                () => setIsProcessedToPay(false)
              }
              className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-3 px-8 rounded font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-3 md:mt-0">
              <i className="fa fa-arrow-left"></i>  Back
            </button>
          </div>
          <div className="bg-[#131313] p-5 rounded-lg mt-7">
            <div className="flex items-center justify-between">
              <h3 className="text-[20px] text-white font-body font-semibold">
                Ticket Quantity
              </h3>
              <span className="text-[20px] text-white font-body font-semibold">
                {passList.reduce((total, pass) => total +
                  pass.quantity, 0)}
              </span>
            </div>
            <div className="border-y border-[#F6F6F6] py-5 my-5">
              {/* <div className="flex items-center justify-between">
                <p className="text-base text-white font-body font-semibold">
                  Dates
                </p>
                <img src={icon} alt="icon" className="inline-block" />
              </div>
              <p className="text-base text-[#DCDCDC] font-body font-normal my-2">
                00/00/0000 | 00/00/0000 | 00/00/0000{" "}
              </p> */}
              <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  Amount
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹{paymentDetails.total_amount}
                </p>
              </div>
              {paymentDetails.discount > 0 && (
                <div className="flex items-center justify-between mb-2">
                  <p className="text-base text-[#DCDCDC] font-body font-normal">
                    Discount applied
                  </p>
                  <p className="text-base text-[#DCDCDC] font-body font-medium">
                    - ₹{paymentDetails.discount}
                  </p>
                </div>
              )}
              <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  Amount Before Tax
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹{paymentDetails.amount}
                </p>
              </div>
              <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  GST
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹{paymentDetails.tax_amount}
                </p>
              </div>
              <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  Convenience Charge
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹{paymentDetails.convenience_charge}
                </p>
              </div>
              {paymentDetails.get_y && paymentDetails.get_y.length > 0 && (
                <div className="flex items-center justify-between mb-2">
                  <p className="text-base text-[#DCDCDC] font-body font-normal">
                    {paymentDetails.get_y[0]['pass_detail']['name']}
                  </p>
                  <p className="text-base text-[#DCDCDC] font-body font-medium">
                    {paymentDetails.get_y[0]['quantity']} MEPASS FREE
                  </p>
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10">
              <div className="text-center md:text-left">
                <span className="text-3xl text-secondary font-body font-semibold uppercase">
                  ₹{paymentDetails.net_amount}
                </span>
                <p className="text-xl text-white font-body font-normal mt-2">
                  Final Amount
                </p>
              </div>
              <button
                onClick={
                  () => processedToPay()
                }
                className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-3 px-8 rounded font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-3 md:mt-0">
                Process to pay{" "}
              </button>
            </div>
          </div>
        </div>
        {/* ----------------card-end---------------- */}

        {/* ----------------------------------------mobile-view-start--------------------------- */}
        <div className="md:hidden block">
          <div className="flex items-start justify-between">
            <h2 className="flex-1 text-[24px] text-white font-body font-medium">
              {event.name ? event.name : ''}
            </h2>
            <button
              onClick={
                () => setIsProcessedToPay(false)
              }
              className="flex-none bg-primary mt-1 ml-1 border border-primary  text-white text-sm font-normal tracking-[0.1rem] p-2 rounded-lg font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase">
              <i className="fa fa-arrow-left"></i>  Back
            </button>
          </div>
          <div className="bg-[#131313] p-5 rounded-lg mt-7">
            <div className="flex items-center justify-between mb-3">
              <h3 className="text-[20px] text-white font-body font-semibold">
                Ticket Quantity
              </h3>

              <span className="text-[20px] text-white font-body font-semibold">
                {passList.reduce((total, pass) => total +
                  pass.quantity, 0)}
              </span>
            </div>
            <div className="border-y border-[#F6F6F6] pb-1 pt-3">
              <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  Amount
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹{paymentDetails.total_amount}
                </p>
              </div>
              {paymentDetails.discount > 0 && (
                <div className="flex items-center justify-between mb-2">
                  <p className="text-base text-[#DCDCDC] font-body font-normal">
                    Discount applied
                  </p>
                  <p className="text-base text-[#DCDCDC] font-body font-medium">
                    ₹{paymentDetails.discount}
                  </p>
                </div>
              )}

              <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  Amount Before Tax
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹{paymentDetails.amount}
                </p>
              </div>

              <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  Convenience Charge
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹{paymentDetails.convenience_charge}
                </p>
              </div>
              <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  GST
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹{paymentDetails && paymentDetails.tax_amount}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between mt-7">
            <div className="">
              <span className="text-2xl text-secondary font-body font-semibold uppercase">
                ₹{paymentDetails.net_amount}
              </span>
            </div>
            <button
              onClick={
                () => processedToPay()
              }
              className="bg-secondary border border-secondary  text-black text-sm font-normal tracking-[0.1rem] p-2 rounded-lg font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase">
              Process to Pay
            </button>
          </div>
        </div>
        {/* ------------------------------------------mobile-view-end-------------------------- */}
      </div>
    </div>
  );
}

export default PaymentOption;
