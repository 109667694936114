
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import event from '../../assets/images/event-banner.png';
import rank from '../../assets/images/small/rank.png';
import withRouter from "../../components/Common/withRouter";
import EventView from "../../components/Event/View";
import api from "../../constants/api";
import { clearData, getData } from "../../store/AppMaster/actions";
import Bussines from "../Home/Bussines";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import SeoDetails from "../../seo/SeoDetails";

const EventCategory = props => {
    const { data } = useSelector(state => ({
        data: state.AppMaster.data,
    }));
    const { loading } = useSelector(state => ({
        loading: state.AppMaster.loading,
    }));
    const [filterData, setFilterData] = useState({
        start: 0,
        limit: 12,
        search: "",
        sort_by: "",
        sort_order: "desc",
        status: "",
        category_id: "",
    });
    const [events, setEvents] = useState([]);
    const [isApiCall, setIsApiCall] = useState(0);
    const [isUpdateData, setIsUpdateData] = useState(false);
    const [totalEventsCount, setTotalEventsCount] = useState(0); // New state for total count of events
    const [cmsData, setCmsData] = useState('');
    const [isCmsData, setIsCmsData] = useState(false);
    const [isCategoryData, setIsCategoryData] = useState(false);
    const [categories, setCategories] = useState([]);
    const dispatch = useDispatch();

    const getListData = useCallback((status, search, start) => {
        setIsUpdateData(true);
        dispatch(clearData());
        filterData.status = status ? status : "";
        filterData.search = search ? search : filterData.search;
        filterData.start = start ? start : 0;
        filterData.category_slug = props.router.params.category_name ? props.router.params.category_name : "";
        filterData.city = props.router.params.city_name ? props.router.params.city_name : "";
        dispatch(getData(filterData, props.router.navigate, api.EventList));
    }, [dispatch, filterData, props.router.navigate]);

    const CmsPage = useCallback(() => {
        setIsCmsData(true);
        let requestCmsData = {
            section_titles: "trending",
            is_page: 'Event'
        }
        if (props.router.params.city_name) {
            requestCmsData.city = props.router.params.city_name;
        }
        if (props.router.params.category_name) {
            requestCmsData.category = props.router.params.category_name;
        }
        dispatch(clearData());
        dispatch(getData(requestCmsData, props.router.navigate, api.CmsPage));
    }, [dispatch, props.router.navigate]);

    const getCategoryList = useCallback(() => {
        setIsCategoryData(true);
        const requestData = {}
        dispatch(clearData());
        dispatch(getData(requestData, props.router.navigate, api.CategoryList));
    }, [dispatch, props.router.navigate]);

    useEffect(() => {
        if (isApiCall === 0) {
            setEvents([]);
            setTotalEventsCount(0)
            getListData();
            //Cms Page Data For Rank
            CmsPage();
            //Get Category List
            getCategoryList();
            setIsApiCall(1);
        }
    }, [isApiCall, getListData, CmsPage, getCategoryList]);

    useEffect(() => {
        if (!isEmpty(data) && !isEmpty(data.eventList) && isUpdateData) {
            setEvents(prevEvents => [...prevEvents, ...data.eventList]);
            setTotalEventsCount(data.filter_count); // Assuming the API returns the total count of events
            setIsUpdateData(false);
        }
    }, [data, isUpdateData]);

    useEffect(() => {
        if (!isEmpty(data) && !isEmpty(data.cmsPage) && !isEmpty(data.cmsPage.trending) && isCmsData) {
            setCmsData(data.cmsPage.trending);
            setIsCmsData(false);
        }
        if (!isEmpty(data) && !isEmpty(data.categoryList) && isCategoryData) {
            setCategories(data.categoryList);
            setIsCategoryData(false);
        }
    }, [data, isCmsData, isCategoryData]);


    const handleScroll = useCallback(() => {
        //if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight) {
        if (!isUpdateData && events.length < totalEventsCount) {
            const start = filterData.start + filterData.limit;
            filterData.start = start
            getListData(filterData.status, filterData.search, start);
        }
        // }
    }, [filterData.status, filterData.search, getListData, isUpdateData, events.length, totalEventsCount]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    const searchEvent = () => {
        setEvents([]);
        filterData.start = 0;
        getListData("", '', 0);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setEvents([]);
            filterData.start = 0;
            getListData("", '', 0);
        }
    };

    return (
        <>
            <SeoDetails
                title={cmsData && cmsData[0] && cmsData[0].seo_title ? cmsData[0].seo_title : ''}
                description={cmsData && cmsData[0] && cmsData[0].short_description ? cmsData[0].short_description : ''}
                ogImage={cmsData && cmsData[0] && cmsData[0].seo_image && cmsData[0].seo_image ? cmsData[0].seo_image : ''}
            />

            <div className="w-full h-full bg-cover pt-28 md:pt-[135px] pb-20" style={{ backgroundImage: `url(${event})` }}>
                <div className="container">
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        freeMode={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                        modules={[Autoplay, FreeMode, Pagination]}
                        className="mySwiper !pb-5"
                    >
                        {!isEmpty(cmsData) && cmsData.map((singleCms, index) => (
                            <>
                                {index < 4 && !isEmpty(singleCms.event) ? (
                                    <SwiperSlide>
                                        <div className='flex items-center gap-3 mb-3'>
                                            {/* <img src={rank} alt="" /> */}
                                            <h2 className='text-secondary text-lg font-body font-bold'>Rank {index + 1}</h2>
                                        </div>
                                        <Link to={"/events/" + singleCms.event.slug}>
                                            <EventView singleEvent={singleCms.event} key={index} />
                                        </Link>
                                    </SwiperSlide>
                                ) : ''}
                            </>
                        ))}

                    </Swiper>
                </div>
            </div>
            <section>
                <div className="bg-neutral pt-14 md:pt-24 pb-14 md:pb-24">
                    <div className="container mx-auto">

                        <div className='hidden md:block'>
                            <h2 className='text-[24px] font-body font-normal tracking-[0.25rem] text-white uppercase'>SEARCH EVENTS</h2>
                            <div className='flex items-center gap-8 mt-5'>
                                <div className="relative rounded-lg shadow-sm w-full">
                                    <div className="cursor-pointer absolute inset-y-0 right-[23px] flex items-center pointer-events-auto"
                                        onClick={
                                            () => searchEvent()
                                        }>
                                        <i className="fas fa-search text-white"></i>
                                    </div>
                                    <input type="text" placeholder="Search Event"
                                        onChange={(e) =>
                                            filterData.search = e.target.value
                                        }
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        className="font-body font-normal text-[#DCDCDC] placeholder:text-white focus:outline-none focus:white block text-[16px] w-full pl-5 py-2.5 px-3 bg-black border border-[#5A5A5A] rounded-full h-full md:h-16" />
                                </div>

                            </div>
                            <div className='flex flex-wrap items-center 2xl:gap-6 lg:gap-2 gap-3 mt-5'>
                                {categories && categories.map((singleCategory, index) => (
                                    <button key={index} onClick={
                                        () => {
                                            if (!isEmpty(props.router.params.city_name)) {
                                                setIsApiCall(0);
                                                props.router.navigate(`/city/${props.router.params.city_name}/category/${singleCategory.slug}/events`);
                                            } else {
                                                setIsApiCall(0);
                                                props.router.navigate(`/category/${singleCategory.slug}/events`);
                                            }
                                        }
                                    }
                                        className={
                                            props.router.params.category_name === singleCategory.slug
                                                ? "text-secondary text-base font-body font-normal py-2 px-[18px] inline bg-black rounded-full border border-secondary hover:border-secondary hover:text-secondary ease-in-out duration-1000"
                                                : "text-white text-base font-body font-normal py-2 px-[18px] inline bg-black rounded-full border border-black hover:border-secondary hover:text-secondary ease-in-out duration-1000"
                                        }>{singleCategory.name}</button>
                                ))}

                            </div>
                        </div>

                        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 mt-10">

                            {events && events.map((singleEvent, index) => (
                                <Link to={"/events/" + singleEvent.slug}>
                                    <EventView singleEvent={singleEvent} key={index} />
                                </Link>
                            ))}

                        </div>
                        {events.length === 0 &&
                            <div className="text-center text-white">
                                <h2 className="text-2xl font-body font-normal text-center">No Events Found</h2>
                            </div>
                        }
                    </div>
                </div>
            </section>
            <Bussines />
        </>
    );
}

export default withRouter(EventCategory);
