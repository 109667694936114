import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import { convertToAmPm } from "../../common/commonFunction";

const EventDetails = ({
    event,
}) => {

    return (

        <>
            <div className="block md:flex items-center justify-between">
                <h2 className="text-3xl md:text-5xl text-white font-body font-medium">
                    {event.name ? event.name : ''}
                </h2>
            </div>
            <div className="block md:flex items-center mt-3 gap-6">
                <p className="text-white text-base font-body font-medium">
                    <i className="fas fa-map-marker-alt mr-2"></i>
                    {event.address ? event.address : ''}, {event.city ? event.city : ''}
                </p>
                <p className="text-white text-base font-body font-medium mr-2 mt-3 md:mt-0">
                    <i className="fas fa-clock mr-2"></i>
                    {event.start_time ? convertToAmPm(event.start_time) : ''}
                </p>
            </div>
        </>

    )
}
EventDetails.propTypes = {
    event: PropTypes.object,
}
export default EventDetails
