import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import { convertToAmPm } from "../../common/commonFunction";

const SelectDateRes = ({
    dropdownResRef,
    toggleResDropdown,
    isDropdownResOpen,
    optionGroupDate,
    selectedDate,
    handleDateDropdown
}) => {

    return (

        <>
            <div className="flex items-center mb-5 mt-5">

                <div className="flex-1">
                    <h3 className="text-base text-white font-body font-light tracking-[0.1rem] uppercase ">
                        Select Date
                    </h3>
                </div>
                <div className="flex-none">
                    <div
                        className="w-full border border-white rounded-full py-2 px-3 relative"
                        ref={dropdownResRef}
                        onClick={toggleResDropdown}
                    >
                        <div className="flex items-center cursor-pointer">
                            <p className="text-lg text-white font-normal flex-1">
                                {selectedDate ? selectedDate.label : "Select Date"}
                            </p>
                            <i className="fas fa-chevron-down text-white flex-none cursor-pointer ml-4"></i>
                        </div>
                        {isDropdownResOpen && (
                            <div className="absolute z-10 max-h-[300px] overflow-auto top-full left-0 mt-2 bg-white text-black rounded-lg shadow-lg w-full">
                                {optionGroupDate.map((optionGroup, index) => (
                                    <p
                                        key={index}
                                        className={`px-2 py-2 cursor-pointer m-2 text-base ${selectedDate && selectedDate.value === optionGroup.value
                                            ? "bg-primary text-white rounded"
                                            : "bg-white hover:bg-[#ffb1b578] hover:rounded"
                                            }`}
                                        onClick={() => {
                                            handleDateDropdown(optionGroup);
                                        }}
                                    >
                                        {optionGroup.label}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>

    )
}
SelectDateRes.propTypes = {

}
export default SelectDateRes
