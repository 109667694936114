import React, { useEffect } from 'react';
import about from "../../assets/images/about-us.png";
import img from "../../assets/images/about-img.png";
import mission from "../../assets/images/mission.png";
import vision from "../../assets/images/vision.png";
import SeoDetails from '../../seo/SeoDetails';

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SeoDetails title="mepass | About Us"
        description="mepass | About Us"
      />
      <div
        className="h-[450px] md:h-[740px] bg-neutral bg-blend-normal pt-32 pb-20  bg-no-repeat"
        style={{ backgroundImage: `url(${about})` }}
      ></div>
      <div className="bg-[#131313] py-14">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-white text-4xl font-body font-light uppercase tracking-[.150em] text-center md:text-left">
                About Us
              </h2>
              <p className="text-[#DCDCDC] text-base font-body font-normal mt-3">
              Welcome to Mepass, where we're redefining the entertainment ticketing experience with innovation and user-centricity at the forefront. Since our inception in 2019, we've embarked on a journey to transform event organisation and attendee experiences through cutting-edge technology and unwavering dedication to our mission.
              </p>
            </div>
            <div>
              <img src={img} alt="about" className="w-full rounded-lg" />
            </div>
          </div>
        </div>
      </div>

      {/* --------------------------------------section-start------------------------------------------------------------ */}
      <div className="bg-neutral py-24">
        <div className="container">
          {/* --------------------------------------our-story-start----------------------------------------------- */}
          <h2 className="text-white text-4xl font-body font-light uppercase tracking-[.150em] text-center md:text-left">
            Our Story
          </h2>
          <p className="text-[#DCDCDC] text-base font-body font-normal mt-3">
            In 2019, Mepass started out wanting to organise events but faced
            challenges. However, we turned a setback into an opportunity by
            creating our own event ticketing service. It quickly became our main
            product thanks to positive feedback. We listened to our users and
            improved continuously to stay relevant and keep them satisfied.
          </p>
          <p className="text-[#DCDCDC] text-base font-body font-normal mt-3">
            After four years of hard work, we launched our revenue model in
            October 2023, marking our official entry into the market. By 2024,
            Mepass grew from a startup to a recognized brand, keeping our core
            values intact. Now, we're ready to take on the entertainment
            ticketing world, armed with our experience and determination to
            succeed.
          </p>
          {/* --------------------------------------our-story-end------------------------------------------------ */}

          {/* --------------------------------------mission-start------------------------------------------------ */}
          <div className="block md:flex items-start gap-8 pt-14">
            <div className="flex-none w-full md:w-[15%] p-2">
              <img src={mission} alt="mission" className="m-auto" />
            </div>
            <div className="flex-1 w-full md:w-[85%]">
              <h2 className="text-white text-4xl font-body font-light uppercase tracking-[.150em] text-center md:text-left">
                Mission
              </h2>
              <p className="text-[#DCDCDC] text-base font-body font-normal mt-3 md:mt-7 text-center md:text-left">
                Redefining Entertainment Ticketing, one Click at a Time.
                Seamlessly connecting entire ecosystem with innovative
                technology. Our mission is to transform the entertainment
                experience, making it convenient, trusted, and unforgettable for
                all.
              </p>
            </div>
          </div>

          {/* --------------------------------------mission-end------------------------------------------------ */}

          {/* --------------------------------------vision-start------------------------------------------------ */}
          <div className="block md:flex items-start gap-8 pt-14">
            <div className="block md:hidden mb-3">
              <img src={vision} alt="mission" className="m-auto" />
            </div>
            <div className="flex-1 w-full md:w-[85%]">
              <h2 className="text-white text-4xl font-body font-light uppercase tracking-[.150em] text-center md:text-left">
                vision
              </h2>
              <p className="text-[#DCDCDC] text-base font-body font-normal mt-3 md:mt-7 text-center md:text-left">
              Mepass aspires to stand as the unparalleled global leader in live entertainment ticketing, born in Bharat but celebrated worldwide. Our unwavering ambition is to break boundaries, dominating our arena. With a steadfast dedication to innovation and user delight along with commitment to innovation and customer satisfaction, we are all set to lead the way, redefining industry benchmarks.
              </p>
            </div>
            <div className="flex-none w-full md:w-[15%] p-2">
              <img
                src={vision}
                alt="mission"
                className="m-auto hidden md:block"
              />
            </div>
          </div>

          {/* --------------------------------------vision-end------------------------------------------------ */}

          {/* -------------------------------core-value-Start------------------------- */}
          <div className="pt-24">
            <div className="bg-[#131313] border border-[#E7E7E7] py-[60px] px-[30px] rounded-[30px]">
              <h2 className="text-[30px] md:text-[48px] font-Urbanist font-[900] text-white uppercase text-center">
                Our Core Values
              </h2>
              <p className="text-base text-white font-body font-normal text-center">
                Our guiding principles that drive everything we do at Mepass
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-[30px]">
                <div className="bg-[#FFFFFF33] border border-secondary p-[16px] rounded-lg">
                  <h3 className="text-2xl text-[#DCDCDC] font-body font-semibold">
                    Innovation
                  </h3>
                  <p className="text-base text-[#DCDCDC] font-body font-normal mt-1">
                    We continuously push the boundaries of technology to deliver
                    cutting-edge solutions that simplify event management and
                    enhance user experiences.
                  </p>
                </div>
                <div className="bg-[#FFFFFF33] border border-secondary p-[16px] rounded-lg">
                  <h3 className="text-2xl text-[#DCDCDC] font-body font-semibold">
                    Customer-Centricity
                  </h3>
                  <p className="text-base text-[#DCDCDC] font-body font-normal mt-1">
                    Our users are at the heart of everything we do. We
                    priorities their needs, listen to their feedback, and strive
                    to exceed their expectations.
                  </p>
                </div>
                <div className="bg-[#FFFFFF33] border border-secondary p-[16px] rounded-lg">
                  <h3 className="text-2xl text-[#DCDCDC] font-body font-semibold">
                    {" "}
                    Reliability
                  </h3>
                  <p className="text-base text-[#DCDCDC] font-body font-normal mt-1">
                    Trust is paramount. We ensure a secure and dependable
                    platform, providing peace of mind to both organizers and
                    attendees.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 pt-[30px]">
                <div className="bg-[#FFFFFF33] border border-secondary p-[16px] rounded-lg">
                  <h3 className="text-2xl text-[#DCDCDC] font-body font-semibold">
                    {" "}
                    Collaboration
                  </h3>
                  <p className="text-base text-[#DCDCDC] font-body font-normal mt-1">
                    We believe in the power of partnerships. By collaborating
                    with industry experts and stakeholders, we create
                    synergistic solutions that drive success.
                  </p>
                </div>
                <div className="bg-[#FFFFFF33] border border-secondary p-[16px] rounded-lg">
                  <h3 className="text-2xl text-[#DCDCDC] font-body font-semibold">
                    {" "}
                    Excellence
                  </h3>
                  <p className="text-base text-[#DCDCDC] font-body font-normal mt-1">
                    We set high standards for ourselves and relentlessly pursue
                    excellence in every aspect of our business, from product
                    development to customer support.
                  </p>
                </div>
              </div>
              <div className="pt-[30px]">
                <p className="text-base text-white font-body font-normal text-center">
                  Join us on this exciting journey as we revolutionize the
                  entertainment ticketing landscape and create unforgettable
                  experiences for all!
                </p>
              </div>
            </div>
          </div>

          {/* -------------------------------core-value-Start------------------------- */}
        </div>
      </div>

      {/* --------------------------------------section-end------------------------------------------------------------ */}
    </>
  );
}

export default AboutUs;
