import { useFormik } from "formik"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import withRouter from "../../components/Common/withRouter"
import SignUpPage from "../../components/SignUp/SignUp"
import api from "../../constants/api"
import { addUpdateNewData, clearData, getData, loginUser } from "../../store/AppMaster/actions"
import SeoDetails from "../../seo/SeoDetails";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const SignUp = props => {
    const currentRoute = location.pathname
    const { data } = useSelector(state => ({
        data: state.AppMaster.data,
    }))
    const { loading } = useSelector(state => ({
        loading: state.AppMaster.loading,
    }))

    const dispatch = useDispatch()
    const [userDetails, setUserDetails] = useState("")
    const [isCheckMobile, setIsCheckMobile] = useState(false)
    const [isCheckEmail, setIsCheckEmail] = useState(false)
    const [mobileNumber, setMobileNumber] = useState("")
    const [isOtpSent, setIsOtpSent] = useState(false)
    const [recaptchaResponse, setRecaptchaResponse] = useState("")
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleReCaptchaVerifyOnSubmit = async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return '';
        }

        const token = await executeRecaptcha('submit');
        return token;
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            mobile: "",
            name: "",
            //   email: "",
        },
        validationSchema: Yup.object({
            mobile: Yup.string()
                .required("Please Enter Your Mobile")
                .length(10, "Mobile number should be exactly 10 digits"),
            //name: Yup.string().required("Please Enter Your Name"),
            //email: Yup.string().email("Invalid email").required("Please Enter Your Email"),
        }),
        onSubmit: values => {
            setIsOtpSent(false)
            const checkEmail = {
                email: values.email,
            }
            setIsCheckEmail(true)
            dispatch(clearData())
            dispatch(getData(checkEmail, props.router.navigate, api.checkEmail))
            setMobileNumber(values.mobile)

        },
    })

    if (!isEmpty(data) && data.STATUS_CODE === 400 && !isOtpSent && isCheckMobile) {
        setIsOtpSent(true)
        setIsCheckMobile(false)
        dispatch(getData({ mobile: mobileNumber }, "", api.sendOtp))
    }

    if (!isEmpty(data) && data.STATUS_CODE === 200 && !isOtpSent && isCheckMobile) {
        validation.setFieldError("mobile", "Mobile number already exists")
        setIsCheckMobile(false)
    }

    if (!isEmpty(data) && data.STATUS_CODE === 200 && !isOtpSent && isCheckEmail) {
        validation.setFieldError("email", "Email already exists")
        setIsCheckEmail(false)
    }

    if (!isEmpty(data) && data.STATUS_CODE === 400 && !isOtpSent && isCheckEmail) {
        setIsCheckEmail(false)
        const checkMobile = {
            mobile: mobileNumber,
        }
        setIsCheckMobile(true)
        dispatch(clearData())
        dispatch(getData(checkMobile, props.router.navigate, api.checkMobile))
    }
    const validationOtp = useFormik({
        enableReinitialize: true,

        initialValues: {
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
            mobile: mobileNumber,
        },
        validationSchema: Yup.object({
            otp1: Yup.string().required("Please Enter Your OTP"),
            otp2: Yup.string().required("Please Enter Your OTP"),
            otp3: Yup.string().required("Please Enter Your OTP"),
            otp4: Yup.string().required("Please Enter Your OTP"),
            otp5: Yup.string().required("Please Enter Your OTP"),
            otp6: Yup.string().required("Please Enter Your OTP"),
        }),
        onSubmit: async values => {
            const newToken = await handleReCaptchaVerifyOnSubmit();
            const requestData = {
                mobile: validation.values.mobile,
                name: validation.values.name,
                email: validation.values.email,
                otp:
                    values.otp1 +
                    values.otp2 +
                    values.otp3 +
                    values.otp4 +
                    values.otp5 +
                    values.otp6,
                gRecaptchaResponse: newToken,
            }
            dispatch(loginUser(requestData, props.router.navigate, api.register))
        },
    })

    const resendOtp = () => {
        dispatch(addUpdateNewData({ mobile: mobileNumber }, "", api.sendOtp))
    }

    if (!isEmpty(data) && !isEmpty(data.userDetails) && isEmpty(userDetails)) {
        setUserDetails(data.userDetails)
        if (props.router.location.state && props.router.location.state.previousPage == "Login") {
            props.router.navigate('/home')
        } else {
            props.router.navigate(-2)
        }
    }

    if (!loading && validation.isSubmitting) {
        validation.isSubmitting = false
    }
    if (!loading && validationOtp.isSubmitting) {
        validationOtp.isSubmitting = false
    }
    return (
        <>
            <SeoDetails title="Mepass - Sign Up"
                description="Mepass - Sign Up"
            />
            <SignUpPage
                validation={validation}
                isOtpSent={isOtpSent}
                validationOtp={validationOtp}
                resendOtp={resendOtp}
                recaptchaResponse={recaptchaResponse}
                isCheckEmail={isCheckEmail}
                isCheckMobile={isCheckMobile}
                props={props}
            />

        </>
    );
}

export default withRouter(SignUp);
