import React from "react"

const StarRating = (rating) => {
    return (
        <>
            {rating === 1 && (
                <i className="fas fa-star text-[#FCB426] text-sm"></i>
            )}
            {rating === 2 && (
                <>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                </>
            )}
            {rating === 3 && (
                <>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                </>
            )}
            {rating === 4 && (
                <>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                </>
            )}
            {rating === 5 && (
                <>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                    <i className="fas fa-star text-[#FCB426] text-sm"></i>
                </>
            )}
        </>
    )
}
export default StarRating
