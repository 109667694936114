import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import banner3 from "../../assets/images/user-profile-bg.png";
import withRouter from "../../components/Common/withRouter";
import UserProfileDetails from "../../components/UserProfile/UserProfile";
import TicketHistory from "../../components/UserProfile/TicketHistory";
import SettingDetails from "../../components/UserProfile/Setting";
import InterestDetails from "../../components/UserProfile/Interest";
import TicketHistoryDetails from "../../components/UserProfile/TicketHistoryDetails";
const UserProfile = props => {
  const fullUrl = window.location.href
  const appUrl = fullUrl.split("/")
  const lastSegment = appUrl[appUrl.length - 1]
  const [customActiveTab, setCustomActiveTab] = useState(1)
  const [isApiCall, setIsApiCall] = useState(0)
  const navigate = useNavigate()
  const toggleCustom = tab => {
    setCustomActiveTab(tab)
    if (tab == 3) {
      setIsApiCall(0)
      navigate(`/user-setting`)
    } else if (tab == 2) {
      setIsApiCall(0)
      navigate(`/user-tickets`)
    } else if (tab == 4) {
      setIsApiCall(0)
      navigate(`/user-interest`)
    } else if (tab == 5) {
      setIsApiCall(0)
      navigate(`/share-history`)
    } else {
      setIsApiCall(0)
      navigate(`/user-profile`)
    }
  }
  useEffect(() => {
    if (lastSegment == "user-profile") {
      setIsApiCall(0)
      setCustomActiveTab(1)
    } else if (lastSegment == "user-setting") {
      setIsApiCall(0)
      setCustomActiveTab(3)
    } else if (lastSegment == "user-tickets") {
      setIsApiCall(0)
      setCustomActiveTab(2)
    } else if (lastSegment == "share-history") {
      setIsApiCall(0)
      setCustomActiveTab(5)
    } else if (lastSegment == "user-interest") {
      setIsApiCall(0)
      setCustomActiveTab(4)
    }
  }, [lastSegment])
  return (
    <>
      <div
        className="w-full h-full md:h-[400px] bg-cover pt-28 bg-right md:pt-[135px] pb-4 md:pb-20"
        style={{ backgroundImage: `url(${banner3})` }}
      >
        <div className="container ">
          <h2 className="text-white text-[38px] md:text-[64px] font-body font-semibold h-[240px] flex items-end">
            User Profile
          </h2>
        </div>
      </div>
      {/* --------------------------------------------------section-start-------------------------------------- */}
      <div className="bg-neutral py-14 md:py-24">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            <div>
              <div className="bg-[#1B1B1B] border border-[#5A5A5A] p-4 rounded-lg">
                <h3 className="text-white text-base font-body font-semibold">
                  General{" "}
                </h3>
                <div className="border-b border-[#5A5A5A] mt-4"></div>
                <button
                  className={`bg-[#131313] p-4  text-base font-body ${customActiveTab === 1
                    ? "font-semibold text-secondary border-secondary"
                    : "font-normal text-white"
                    } border border-[#5A5A5A] rounded-lg w-full mt-4 text-left`}
                  onClick={() => toggleCustom(1)}
                >
                  Profile Setting
                </button>
                <button
                  className={`bg-[#131313] p-4 text-base font-body ${customActiveTab === 2 || customActiveTab === 5
                    ? "font-semibold text-secondary border-secondary"
                    : "font-normal text-white"
                    } border border-[#5A5A5A] rounded-lg w-full mt-4 text-left`}
                  onClick={() => { customActiveTab === 2 ? toggleCustom(5) : toggleCustom(2) }
                  }
                >
                  Ticket Details
                </button>
                <button
                  className={`bg-[#131313] p-4 text-base font-body ${customActiveTab === 3
                    ? "font-semibold text-secondary border-secondary"
                    : "font-normal text-white"
                    } border border-[#5A5A5A] rounded-lg w-full mt-4 text-left`}
                  onClick={() => toggleCustom(3)}
                >
                  Settings & Permission
                </button>
                <button
                  className={`bg-[#131313] p-4 text-base font-body ${customActiveTab === 4
                    ? "font-semibold text-secondary border-secondary"
                    : "font-normal text-white"
                    } border border-[#5A5A5A] rounded-lg w-full mt-4 text-left`}
                  onClick={() => toggleCustom(4)}
                >
                  Interest
                </button>
                <Link
                  to="/logout"
                  state={{ previousPage: 'Login' }}
                  className="bg-[#131313] p-4 text-primary text-base font-body font-medium border border-[#5A5A5A] block rounded-lg w-full mt-4 text-left"
                >
                  <i className="fas fa-sign-out-alt mr-3"></i>Log out
                </Link>
              </div>
            </div>
            <div className="col-span-2">
              {customActiveTab === 1 && (
                <UserProfileDetails props={props} isApiCall={isApiCall} setIsApiCall={setIsApiCall}></UserProfileDetails>
              )}
              {/* -----------------------------ticket-details-start----------------------- */}
              {customActiveTab === 2 && (
                <TicketHistory props={props} isApiCall={isApiCall} setIsApiCall={setIsApiCall}></TicketHistory>
              )}
              {/* -----------------------------ticket-details-end----------------------- */}
              {/* -----------------------------setting-details-start----------------------- */}
              {customActiveTab === 3 && (
                <SettingDetails props={props} isApiCall={isApiCall} setIsApiCall={setIsApiCall}></SettingDetails>
              )}
              {/* -----------------------------setting-details-end----------------------- */}
              {customActiveTab === 4 && (
                <InterestDetails props={props} isApiCall={isApiCall} setIsApiCall={setIsApiCall}></InterestDetails>
              )}
              {customActiveTab === 5 && (
                <TicketHistoryDetails props={props} isApiCall={isApiCall} setIsApiCall={setIsApiCall}></TicketHistoryDetails>
              )}
            </div>
          </div>
        </div>
      </div >
      {/* --------------------------------------------------section-end-------------------------------------- */}
    </>
  );
}
export default withRouter(UserProfile);