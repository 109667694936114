import React from 'react';
import icon from '../../assets/images/small/clear-notification.png'
import icon2 from '../../assets/images/small/notification.png'
function Notification() {
    return (
        <>
            <div className="bg-neutral pt-32 pb-20  bg-no-repeat">
                <div className='container'>
                    <div className='flex items-center justify-end'>
                        <button className='text-base text-white font-body font-medium bg-[#131313] py-2 px-4 inline-block rounded-full'>
                            <img src={icon} alt="" className='inline-block mr-2' />
                            Clear Notification
                        </button>
                    </div>
                    
                    {/* ----------------card-start--------------- */}
                    <div className='bg-[#131313] p-4 rounded-lg mt-5'>
                        <div className='flex items-start md:items-center gap-3'>
                            <div className='flex-none w-1/5 md:w-[10%] lg:w-[5%]'>
                                <div className='bg-primary rounded-full p-3 inline-block'>
                                    <img src={icon2} alt="" />
                                </div>
                            </div>
                            <div className='flex-auto w-[95%]'>
                                <div className='flex items-center justify-between'>
                                    <h3 className='text-2xl text-white font-body font-semibold'>Title</h3>
                                    <p className='text-base text-secondary font-body font-semibold'>July 10,2023</p>
                                </div>
                                <p className='text-sm md:text-lg text-white font-body font-normal mt-2 w-[91%] hidden md:block'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                <p className='text-sm text-white font-body font-normal mt-2 w-[61%] md:hidden block'>Lorem Ipsum is simply dummy text of the printing.</p>
                            </div>
                        </div>
                    </div>
                     {/* ----------------card-end---------------- */}

                    {/* ----------------card-start--------------- */}
                    <div className='bg-[#131313] p-4 rounded-lg mt-5'>
                        <div className='flex items-start md:items-center gap-3'>
                            <div className='flex-none w-1/5 md:w-[10%] lg:w-[5%]'>
                                <div className='bg-primary rounded-full p-3 inline-block'>
                                    <img src={icon2} alt="" />
                                </div>
                            </div>
                            <div className='flex-auto w-[95%]'>
                                <div className='flex items-center justify-between'>
                                    <h3 className='text-2xl text-white font-body font-semibold'>Title</h3>
                                    <p className='text-base text-secondary font-body font-semibold'>July 10,2023</p>
                                </div>
                                <p className='text-sm md:text-lg text-white font-body font-normal mt-2 w-[91%] hidden md:block'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                <p className='text-sm text-white font-body font-normal mt-2 w-[61%] md:hidden block'>Lorem Ipsum is simply dummy text of the printing.</p>
                            </div>
                        </div>
                    </div>
                     {/* ----------------card-end---------------- */}

                    {/* ----------------card-start--------------- */}
                    <div className='bg-[#131313] p-4 rounded-lg mt-5'>
                        <div className='flex items-start md:items-center gap-3'>
                            <div className='flex-none w-1/5 md:w-[10%] lg:w-[5%]'>
                                <div className='bg-primary rounded-full p-3 inline-block'>
                                    <img src={icon2} alt="" />
                                </div>
                            </div>
                            <div className='flex-auto w-[95%]'>
                                <div className='flex items-center justify-between'>
                                    <h3 className='text-2xl text-white font-body font-semibold'>Title</h3>
                                    <p className='text-base text-secondary font-body font-semibold'>July 10,2023</p>
                                </div>
                                <p className='text-sm md:text-lg text-white font-body font-normal mt-2 w-[91%] hidden md:block'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                <p className='text-sm text-white font-body font-normal mt-2 w-[61%] md:hidden block'>Lorem Ipsum is simply dummy text of the printing.</p>
                            </div>
                        </div>
                    </div>
                    {/* ----------------card-end---------------- */}

                    {/* ----------------card-start--------------- */}
                    <div className='bg-[#131313] p-4 rounded-lg mt-5'>
                        <div className='flex items-start md:items-center gap-3'>
                            <div className='flex-none w-1/5 md:w-[10%] lg:w-[5%]'>
                                <div className='bg-primary rounded-full p-3 inline-block'>
                                    <img src={icon2} alt="" />
                                </div>
                            </div>
                            <div className='flex-auto w-[95%]'>
                                <div className='flex items-center justify-between'>
                                    <h3 className='text-2xl text-white font-body font-semibold'>Title</h3>
                                    <p className='text-base text-secondary font-body font-semibold'>July 10,2023</p>
                                </div>
                                <p className='text-sm md:text-lg text-white font-body font-normal mt-2 w-[91%] hidden md:block'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                <p className='text-sm text-white font-body font-normal mt-2 w-[61%] md:hidden block'>Lorem Ipsum is simply dummy text of the printing.</p>
                            </div>
                        </div>
                    </div>
                     {/* ----------------card-end---------------- */}
                </div>
            </div>

        </>
    );
}

export default Notification;
