import React from 'react';
import { Link } from 'react-router-dom';
import ArtistView from '../../Artist/View';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import { isEmpty } from "lodash";


const Artist = ({ artists, props }) => {
  return (
    <>
      {!isEmpty(artists) ? (
        <div className="bg-[#212121] pt-14">
          <h2 className="text-2xl md:text-[40px] font-body font-light tracking-[.150em] text-white uppercase">
            Artist
          </h2>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            freeMode={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            modules={[Autoplay, FreeMode, Pagination]}
            className="mySwiper mt-9"
          >
            {artists && artists.map((singleArtist, index) => (
              <>
                <SwiperSlide>
                  <Link to={"/artiest/" + singleArtist.slug}>
                    <ArtistView singleArtist={singleArtist} key={index} pageTitle="EventDetails" />
                  </Link>
                </SwiperSlide>
              </>
            ))}
          </Swiper>
        </div>
      ) : ''}
    </>
  )
}

export default Artist
