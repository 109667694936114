import PropTypes from "prop-types"
import React from "react"
import icon4 from "../../assets/images/small/location.png"

const Textarea = ({
  validation,
  name,
  label,
  row,
  isRequire,
  isNeeded,
  isEditEnabled,
  isDisplayPlaceHolder,
  isLabelDisplay,
  className,
}) => {
  return (
    <div>
      <label className="text-base font-body font-medium text-[#5A5A5A] mb-2 block">
        {isLabelDisplay ? label : ""}
        {isRequire && <span className="text-red-500">*</span>}
      </label>
      <div className="relative">
        <textarea
          name={name}
          readOnly={isEditEnabled}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values[name] || ""}
          rows={row}
          className={className ?   className:"border border-[#E7E7E7] text-black text-base font-normal font-body rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full py-4 px-5 bg-[#ffffff]"}
        
          placeholder={isDisplayPlaceHolder ? "Enter " + label : ""}
        />
        {isNeeded && (
          <img src={icon4} alt="icon" className="absolute top-4 right-3" />
        )}
      </div>

      {validation.touched[name] && validation.errors[name] ? (
        <div className="text-red-500">{validation.errors[name]}</div>
      ) : null}
    </div>
  )
}

Textarea.propTypes = {
  validation: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  row: PropTypes.number,
  isRequire: PropTypes.bool,
  isNeeded: PropTypes.bool,
}

export default Textarea
