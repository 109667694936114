import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import { convertToAmPm } from "../../common/commonFunction";

const PassOffersRes = ({
    passPaymentCalculation,
    passOffers,
    icon,
    handlePromoCodeApply,
    promoCode,
    setPromoCode
}) => {

    return (

        <>
            <div className="bg-black p-5 md:p-7 rounded-lg mt-7">
                <h3 className="text-[22px] md:text-[40px] text-white font-body font-light tracking-[0.3rem] uppercase mb-5">
                    Available Offers
                </h3>
                <div className="bg-[#131313] p-5 rounded-lg mt-7">
                    <div className="block md:flex items-center gap-4">
                        <div className="flex-auto w-full md:w-[80%] xl:w-[90%]">
                            <div className="relative">
                                <input
                                    type="text"
                                    className="bg-[#212121] pl-10 form-input border border-[#4c4c4c] text-white text-lg font-medium font-body rounded-lg placeholder:text-white focus:ring-blue-500 focus:border-blue-500 block w-full py-4 px-5"
                                    placeholder="Enter promo code "
                                    value={promoCode}
                                    onChange={(e) => setPromoCode(e.target.value)}
                                />
                                <div className="absolute left-4 top-[17px]">
                                    <i className="fas fa-tags text-white text-lg"></i>
                                </div>
                            </div>
                        </div>
                        <div className="flex-none w-full md:w-[20%] xl:w-[10%] mt-4 md:mt-0">

                            <button onClick={() =>
                                handlePromoCodeApply()
                            } className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase">
                                Apply
                            </button>
                        </div>
                    </div>
                    <div className="w-full md:w-[80%] xl:w-[90%] ">
                        {passPaymentCalculation.discount_status === "success" && (
                            <p className="text-green-500 text-sm font-body font-normal mt-2">
                                {passPaymentCalculation.discount_message}
                            </p>
                        )}
                        {passPaymentCalculation.discount_status === "error" && (
                            <p className="text-red-500 text-sm font-body font-normal mt-2">
                                {passPaymentCalculation.discount_message}
                            </p>
                        )}
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-7 mt-7">
                    {passOffers.map((offer, indexOff) => (
                        <>
                            {offer.visible_to_public == 'Yes' &&
                                <div key={indexOff} className="bg-[#131313] p-[10px] rounded-lg ">

                                    <>

                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-3">
                                                <img src={icon} alt="" className="inline-block" />
                                                <h3 className="text-sm text-white font-body font-medium uppercase">
                                                    {offer.name ? offer.name : ''}
                                                </h3>
                                            </div>
                                            <button onClick={() =>
                                                setPromoCode(offer.offer_code)
                                            } className="border border-secondary  text-secondary text-sm font-normal tracking-[0.1rem] py-2 px-2 md:px-8 rounded font-body  uppercase">
                                                Apply
                                            </button>
                                        </div>
                                        {offer.description && (
                                            <div className="flex items-center gap-4">
                                                <div className="flex-auto w-[85%]">
                                                    <p className="text-base text-white font-body font-normal mt-4">
                                                        {offer.description}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                </div>
                            }

                        </>
                    ))}
                </div>
            </div>
        </>

    )
}
PassOffersRes.propTypes = {

}
export default PassOffersRes
