import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const OTP = ({ validation, name, label, isRequire, readOnly }) => {
  // Create a ref array for input elements
  const inputRefs = Array.from({ length: 6 }).map(() => useRef(null));

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const isDigit = /^\d$/.test(value);

    // Set the field value only if the input is valid (a digit) or empty
    if (isDigit || value === "") {
      validation.setFieldValue(e.target.name, value);

      if (isDigit) {
        // If the value is a digit and not the last input, move focus to the next input
        if (index < inputRefs.length - 1) {
          inputRefs[index + 1].current.focus();
        }
      }
    } else {
      // If the value is invalid, do nothing (focus stays on the current input)
      e.target.value = ""; // Clear the input
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (e.target.value === "" && index > 0) {
        // Move focus to the previous input if Backspace is pressed and the input is empty
        inputRefs[index - 1].current.focus();
      } else {
        // Clear the current input and keep the focus
        e.target.value = "";
        validation.setFieldValue(e.target.name, "");
      }
    }
  };

  useEffect(() => {
    // Automatically focus on the first input when component mounts
    inputRefs[0].current.focus();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="flex justify-center space-x-2">
      {Array.from({ length: 6 }).map((_, index) => (
          <input
            key={index}
            id={`otp${index + 1}`}
            name={`otp${index + 1}`}
            type="text"
            maxLength="1"
            inputMode="numeric"
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onBlur={validation.handleBlur}
            value={validation.values[`otp${index + 1}`] || ""}
            readOnly={readOnly}
            ref={inputRefs[index]} // Assign ref to each input
            className="bg-[#131313] border border-[#131313] w-[40px] h-[40px] sm:w-[48px] sm:h-[48px] text-white text-base font-normal font-body rounded-[10px] placeholder:text-white focus:ring-blue-500 focus:border-blue-500 flex items-center justify-center text-center"
            placeholder=""
          />
        ))}
      </div>

      <div className="text-center mt-2 sm:mt-3">
        {validation.touched["otp1"] && validation.errors["otp1"] ? (
          <div className="text-red-500 text-xs">
            {validation.errors["otp1"]}
          </div>
        ) : validation.touched["otp2"] && validation.errors["otp2"] ? (
          <div className="text-red-500 text-xs">
            {validation.errors["otp2"]}
          </div>
        ) : validation.touched["otp3"] && validation.errors["otp3"] ? (
          <div className="text-red-500 text-xs">
            {validation.errors["otp3"]}
          </div>
        ) : validation.touched["otp4"] && validation.errors["otp4"] ? (
          <div className="text-red-500 text-xs">
            {validation.errors["otp4"]}
          </div>
        ) : validation.touched["otp5"] && validation.errors["otp5"] ? (
          <div className="text-red-500 text-xs">
            {validation.errors["otp5"]}
          </div>
        ) : validation.touched["otp6"] && validation.errors["otp6"] ? (
          <div className="text-red-500 text-xs">
            {validation.errors["otp6"]}
          </div>
        ) : null}
      </div>
    </div>
  );
};

OTP.propTypes = {
  validation: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  isRequire: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default OTP;
