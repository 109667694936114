import React from "react"
import bannerImageDefault from '../../assets/images/default/event-image.jpg'
import icon4 from '../../assets/images/small/megaphone-dark.png'
import icon2 from '../../assets/images/small/megaphone.png'
import icon3 from '../../assets/images/small/percentage-red.png'
import icon5 from '../../assets/images/small/percentage-yellow.png'
import { convertToAmPm, convertToDate } from "../../common/commonFunction";
import { Link } from "react-router-dom"
import withRouter from "../Common/withRouter"

const EventView = ({ singleEvent, props }) => {
    return (
        <>
            <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-1 md:p-[15px] rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary " key={singleEvent.id}>
                <div class='flex items-start xl:items-center justify-between gap-3 md:gap-5'>
                    <h3 class='text-white text-base md:text-2xl font-body font-normal line-clamp-1 flex-1'>{singleEvent.name}</h3>
                    <div className="flex-none">
                        {singleEvent.rating > 0 ? (
                            <div class='bg-primary py-2 px-3 md:inline-block hidden rounded-full'>
                                <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                    <i className="fas fa-star mr-2 text-secondary"></i><b>{singleEvent.rating ? singleEvent.rating : 1}</b>
                                </p>
                            </div>
                        ) : ''}
                        {singleEvent.rating > 0 ? (
                            <p class='text-secondary text-sm font-body whitespace-nowrap block md:hidden'>
                                <i className="fas fa-star mr-2 text-secondary"></i><b>{singleEvent.rating ? singleEvent.rating : 1}</b>
                            </p>
                        ) : ''}
                    </div>
                </div>
                <div className="md:block hidden">
                    {singleEvent.dates && singleEvent.dates[0] && singleEvent.dates[0]['address'] ?
                        <p className="text-white text-[16px] font-body font-normal line-clamp-1 mt-2">
                            <i className="fas fa-map-marker-alt text-white mr-2"></i>
                            {singleEvent.dates[0]['address']}
                        </p>
                        : ''
                    }
                </div>
                <div className='hidden md:block'>
                    <div className="flex items-center justify-between mt-3">
                        <div class='bg-black py-2 px-2 inline-block rounded'>
                            <p class='text-white text-xs font-body font-medium'>
                                <i className="fas fa-calendar mr-1"></i>
                                {singleEvent.dates && singleEvent.dates[0] ? singleEvent.dates[0]['date_formatted'] && convertToDate(singleEvent.dates[0]['date']) : ''}
                                {singleEvent && singleEvent.dates && singleEvent.dates[0] && singleEvent.dates[0]['start_time'] ? ' - ' : ''}
                                {singleEvent && singleEvent.dates && singleEvent.dates[0] && singleEvent.dates[0]['start_time'] ? convertToAmPm(singleEvent.dates[0]['start_time']) : ''}
                            </p>
                        </div>
                        <div className='group-hover:hidden'>
                            <div className='flex items-center gap-1'>
                                {singleEvent.feature ? (
                                    <div className="bg-primary w-[32px] h-[30px] flex items-center justify-center rounded-md p-2">
                                        <img src={icon4} alt="" className='inline-block' />
                                    </div>
                                ) : ''}
                                {singleEvent.offer_available ? (
                                    <div className="bg-primary w-[32px] h-[30px] flex items-center justify-center rounded-md p-2">
                                        <img src={icon5} alt="" className='inline-block' />
                                    </div>
                                ) : ''}
                            </div>
                        </div>
                        <div className='hidden group-hover:block'>
                            <div className='flex items-center gap-1'>
                                {singleEvent.feature ? (
                                    <div className="bg-secondary w-[32px] h-[30px] flex items-center justify-center rounded-md p-2">
                                        <img src={icon2} alt="" className='inline-block' />
                                    </div>
                                ) : ''}
                                {singleEvent.offer_available ? (
                                    <div className="bg-secondary w-[32px] h-[30px] flex items-center justify-center rounded-md p-2">
                                        <img src={icon3} alt="" className='inline-block' />
                                    </div>
                                ) : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='md:hidden block mt-3'>
                    <div className='group-hover:hidden'>
                        <div className='flex items-center gap-2'>
                            {singleEvent.feature ? (
                                <div className="bg-primary w-[32px] h-[30px] flex items-center justify-center rounded-md p-2">
                                    <img src={icon4} alt="" className='inline-block' />
                                </div>
                            ) : ''}
                            {singleEvent.offer_available ? (
                                <div className="bg-primary w-[32px] h-[30px] flex items-center justify-center rounded-md p-2">
                                    <img src={icon5} alt="" className='inline-block' />
                                </div>
                            ) : ''}
                        </div>
                    </div>
                    <div className='hidden group-hover:block'>
                        <div className='flex items-center gap-2'>
                            {singleEvent.feature ? (
                                <div className="bg-secondary w-[32px] h-[30px] flex items-center justify-center rounded-md p-2">
                                    <img src={icon2} alt="" className='inline-block' />
                                </div>
                            ) : ''}
                            {singleEvent.offer_available ? (
                                <div className="bg-secondary w-[32px] h-[30px] flex items-center justify-center rounded-md p-2">
                                    <img src={icon3} alt="" className='inline-block' />
                                </div>
                            ) : ''}
                        </div>
                    </div>
                </div>
                <div class='relative'>
                    <img src={singleEvent.banner_image ? singleEvent.banner_image : bannerImageDefault} alt="" className="w-full aspect-[9/16] object-fill rounded-lg mt-4" />
                </div>
                <div className='hidden md:block'>
                    <div className="flex items-center justify-between my-3 px-[10px] group-hover:py-2 duration-1000 ease-in-out">
                        {singleEvent.no_of_ratings ? (
                            <div class='py-[7px] px-[10px] border border-[#FCB426] rounded-3xl text-sm font-bold font-body text-white'>
                                <i className="fas fa-user-friends mr-3"></i>{singleEvent.no_of_ratings ? singleEvent.no_of_ratings : ''}
                            </div>
                        ) : ''}
                        <div className="text-end">
                            {singleEvent.price_without_tax > 0 ? (
                                <h5 className="text-2xl font-semibold text-secondary font-body"><b>₹{singleEvent.price_without_tax ? singleEvent.price_without_tax : ''}</b></h5>

                            ) : ''}

                            <div className='flex items-center'>
                                {singleEvent.original_price > 0 && singleEvent.original_price != singleEvent.price_without_tax ? (
                                    <h5 className="text-base font-medium text-[#c8c8c8]">
                                        <del>₹ {singleEvent.original_price}</del>
                                    </h5>
                                ) : ''}
                                {singleEvent.percentage > 0 &&
                                    <p className='text-base font-medium text-[#388e3c] ml-3'>{singleEvent.percentage}% off</p>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <div className='block md:hidden mt-2'>
                    <div className="text-end">
                        {singleEvent.price_without_tax > 0 ? (
                            <h5 className="text-3xl font-semibold text-secondary font-body"><b>₹{singleEvent.price_without_tax ? singleEvent.price_without_tax : ''}</b></h5>

                        ) : ''}

                        <div className='flex items-center justify-end'>
                            {singleEvent.original_price > 0 && singleEvent.original_price != singleEvent.price_without_tax ? (
                                <h5 className="text-base font-medium text-[#c8c8c8]">
                                    <del>₹ {singleEvent.original_price}</del>
                                </h5>
                            ) : ''}
                            {singleEvent.percentage > 0 &&
                                <p className='text-base font-medium text-[#388e3c] ml-3'>{singleEvent.percentage}% off</p>
                            }
                        </div>

                    </div>
                </div>
                {
                    singleEvent.pass_created === true && singleEvent.pass_available === true ? (
                        <Link to={`/events/${singleEvent.slug}/book-tickets`}>
                            <button className="bg-primary border border-primary  text-white text-sm md:text-base font-normal tracking-[.25em] py-2 px-2 md:px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0">
                                Book
                            </button>
                        </Link>
                    ) : singleEvent.pass_created === false ? (
                        <Link to={`/events/${singleEvent.slug}`}>
                            <button className="bg-primary border border-primary  text-white text-sm md:text-base font-normal tracking-[.25em] py-2 px-2 md:px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0" >
                                View Event
                            </button>
                        </Link>
                    ) : singleEvent.pass_available === false && singleEvent.pass_created === true ? (
                        <button className="bg-primary border border-primary  text-white text-sm md:text-base font-normal tracking-[.25em] py-2 px-2 md:px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0" disabled>
                            Sold
                        </button>
                    ) : null
                }
            </div>
        </>
    )
}

export default withRouter(EventView)
