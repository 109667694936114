import React from "react"
import event from '../../assets/images/event-banner.png'
import img1 from '../../assets/images/featured-img.png'
import img2 from '../../assets/images/featured-img2.png'
import img3 from '../../assets/images/featured-img3.png'
import img4 from '../../assets/images/event-img.png'
import img5 from '../../assets/images/kal-ki-chinta.png'
import img6 from '../../assets/images/para.png'
import img7 from '../../assets/images/pannu.png'
import img8 from '../../assets/images/bashi.png'
import img9 from '../../assets/images/vivek.png'
import sartaaj from '../../assets/images/sartaj.png'
import Bussines from "../Home/Bussines";
import icon2 from '../../assets/images/small/megaphone.png'
import icon3 from '../../assets/images/small/percentage-red.png'
import icon4 from '../../assets/images/small/megaphone-dark.png'
import icon5 from '../../assets/images/small/percentage-yellow.png'
import rank from '../../assets/images/small/rank.png'
import { Link } from 'react-router-dom';
function Exhibitions() {
    return (
        <>
            <div className="w-full h-full bg-cover pt-28 md:pt-[135px] pb-20" style={{ backgroundImage: `url(${event})` }}>
                <div className="container">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {/* first-card */}
                        <div>
                            <div className='flex items-center gap-3 mb-3'>
                                <img src={rank} alt="" />
                                <h2 className='text-secondary text-lg font-body font-bold'>Rank 1</h2>
                            </div>
                            <Link to="/event-category/details">
                                <div className="group bg-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-5 rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 ">
                                    <div class='flex items-start xl:items-center justify-between gap-5'>
                                        <h3 class='text-white text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                        <div class='bg-primary py-2 px-3 inline-block rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                    </div>
                                    <p class='text-white text-[16px] font-body font-normal mt-2'>
                                        <i className="fas fa-map-marker-alt mr-2"></i>
                                        Sindhu bhavan, Ahmedabad
                                    </p>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div class='relative'>
                                        <img src={img1} alt="" className="w-full mt-4" />
                                    </div>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                    <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase">Book</button>
                                </div>
                            </Link>
                        </div>
                        {/* secound-card */}
                        <div>
                            <div className='flex items-center gap-3 mb-3'>
                                <img src={rank} alt="" />
                                <h2 className='text-secondary text-lg font-body font-bold'>Rank 2</h2>
                            </div>
                            <Link to="/event-category/details">
                                <div className="group bg-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-5 rounded-lg transition-all duration-1000 hover:translate-y-1 ">
                                    <div class='flex items-start xl:items-center justify-between gap-5'>
                                        <h3 class='text-white text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                        <div class='bg-primary py-2 px-3 inline-block rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                    </div>
                                    <p class='text-white text-[16px] font-body font-normal mt-2'>
                                        <i className="fas fa-map-marker-alt mr-2"></i>
                                        Sindhu bhavan, Ahmedabad
                                    </p>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div class='relative'>
                                        <img src={img3} alt="" className="w-full mt-4" />
                                    </div>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                    <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase">Book</button>
                                </div>
                            </Link>
                        </div>
                        {/* third-card */}
                        <div>
                            <div className='flex items-center gap-3 mb-3'>
                                <img src={rank} alt="" />
                                <h2 className='text-secondary text-lg font-body font-bold'>Rank 3</h2>
                            </div>
                            <Link to="/event-category/details">
                                <div className="group bg-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-5 rounded-lg transition-all duration-1000 hover:translate-y-1 ">
                                    <div class='flex items-start xl:items-center justify-between gap-5'>
                                        <h3 class='text-white text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                        <div class='bg-primary py-2 px-3 inline-block rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                    </div>
                                    <p class='text-white text-[16px] font-body font-normal mt-2'>
                                        <i className="fas fa-map-marker-alt mr-2"></i>
                                        Sindhu bhavan, Ahmedabad
                                    </p>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div class='relative'>
                                        <img src={sartaaj} alt="" className="w-full mt-4" />
                                    </div>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                    <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase">Book</button>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* search Event start */}
            <section>
                <div className="bg-neutral pt-14 md:pt-24 pb-14 md:pb-24">
                    <div className="container mx-auto ">
                        <div>
                            <h2 className='text-[24px] font-body font-normal tracking-[0.25rem] text-white uppercase'>SEARCH EVENTS</h2>
                            <div className='flex items-center gap-8 mt-5'>
                                <div className="relative rounded-lg shadow-sm w-full">
                                    <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-auto">
                                        <i className="fas fa-search text-white"></i>
                                    </div>
                                    <input type="text" placeholder="Search Event" className="font-body font-normal text-[#DCDCDC] placeholder:text-white focus:outline-none focus:white block text-[16px] w-full pl-10 py-2.5 px-3 bg-black border border-[#5A5A5A] rounded-full h-full md:h-16" />
                                </div>
                                <div className='w-[62px] h-[47px] md:h-[57px] flex items-center justify-center bg-primary rounded-full'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-white">
                                        <path d="M18.75 12.75h1.5a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5ZM12 6a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 12 6ZM12 18a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 12 18ZM3.75 6.75h1.5a.75.75 0 1 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5ZM5.25 18.75h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5ZM3 12a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 3 12ZM9 3.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM12.75 12a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM9 15.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" />
                                    </svg>
                                </div>
                            </div>
                            {/* --------------------------event-button-start------------------------ */}

                            <div className='flex flex-wrap items-center 2xl:gap-6 lg:gap-2 gap-3 mt-5'>
                                <button className='text-white text-base font-body font-normal py-2 px-[18px] inline bg-black rounded-full border border-black hover:border-secondary hover:text-secondary ease-in-out duration-1000'>Plays</button>
                                <button className='text-white text-base font-body font-normal py-2 px-[18px] inline bg-black rounded-full border border-black hover:border-secondary hover:text-secondary ease-in-out duration-1000'>Sports</button>

                                <button className='text-white text-base font-body font-normal py-2 px-[18px] inline bg-black rounded-full border border-black hover:border-secondary hover:text-secondary ease-in-out duration-1000'>Plays</button>
                                <button className='text-white text-base font-body font-normal py-2 px-[18px] inline bg-black rounded-full border border-black hover:border-secondary hover:text-secondary ease-in-out duration-1000'>Navratri</button>
                                <button className='text-white text-base font-body font-normal py-2 px-[18px] inline bg-black rounded-full border border-black hover:border-secondary hover:text-secondary ease-in-out duration-1000'>Concert</button>
                                <button className='text-white text-base font-body font-normal py-2 px-[18px] inline bg-black rounded-full border border-black hover:border-secondary hover:text-secondary ease-in-out duration-1000'>Holi</button>
                                <button className='text-white text-base font-body font-normal py-2 px-[18px] inline bg-black rounded-full border border-black hover:border-secondary hover:text-secondary ease-in-out duration-1000'>Sports</button>
                                <button className='text-white text-base font-body font-normal py-2 px-[18px] inline bg-black rounded-full border border-black hover:border-secondary hover:text-secondary ease-in-out duration-1000'>Concert</button>
                                <button className='text-white text-base font-body font-normal py-2 px-[18px] inline bg-black rounded-full border border-black hover:border-secondary hover:text-secondary ease-in-out duration-1000'>Sports</button>
                                <button className='text-white text-base font-body font-normal py-2 px-[18px] inline bg-black rounded-full border border-black hover:border-secondary hover:text-secondary ease-in-out duration-1000'>Holi</button>
                                <button className='text-white text-base font-body font-normal py-2 px-[18px] inline bg-black rounded-full border border-black hover:border-secondary hover:text-secondary ease-in-out duration-1000'>Concert</button>
                            </div>

                            {/* --------------------------event-button-end------------------------ */}
                        </div>
                        {/*--------------- column start------------------------ */}
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-7 mt-10">
                            {/* first-card */}
                            <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-1 md:p-5 rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary ">
                                <div class='flex items-start xl:items-center justify-between gap-3 md:gap-5'>
                                    <h3 class='text-white text-base md:text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                    <div>
                                        <div class='bg-primary py-2 px-3 md:inline-block hidden rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                        <p class='text-secondary text-sm font-body whitespace-nowrap block md:hidden'>
                                            <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                        </p>
                                    </div>
                                </div>
                                <p class='text-white text-[16px] font-body font-normal mt-2 md:block hidden'>
                                    <i className="fas fa-map-marker-alt mr-2"></i>
                                    Sindhu bhavan, Ahmedabad
                                </p>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='group-hover:hidden'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon4} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon5} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden group-hover:block'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon2} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon3} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:hidden block mt-3'>
                                    <div className='group-hover:hidden'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon4} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon5} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden group-hover:block'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='relative'>
                                    <img src={img1} alt="" className="w-full mt-4" />
                                </div>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                </div>
                                <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0">Book</button>
                            </div>
                            {/* secound-card */}
                            <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-1 md:p-5 rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary">
                                <div class='flex items-start xl:items-center justify-between gap-3 md:gap-5'>
                                    <h3 class='text-white text-base md:text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                    <div>
                                        <div class='bg-primary py-2 px-3 md:inline-block hidden rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                        <p class='text-secondary text-sm font-body whitespace-nowrap block md:hidden'>
                                            <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                        </p>
                                    </div>
                                </div>
                                <p class='text-white text-[16px] font-body font-normal mt-2 md:block hidden'>
                                    <i className="fas fa-map-marker-alt mr-2"></i>
                                    Sindhu bhavan, Ahmedabad
                                </p>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='group-hover:hidden'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon4} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon5} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden group-hover:block'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon2} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon3} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:hidden block mt-3'>
                                    <div className='group-hover:hidden'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon4} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon5} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden group-hover:block'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='relative'>
                                    <img src={img3} alt="" className="w-full mt-4" />
                                </div>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                </div>
                                <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0">Book</button>
                            </div>
                            {/* third-card */}
                            <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-1 md:p-5 rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary">
                                <div class='flex items-start xl:items-center justify-between gap-3 md:gap-5'>
                                    <h3 class='text-white text-base md:text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                    <div>
                                        <div class='bg-primary py-2 px-3 md:inline-block hidden rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                        <p class='text-secondary text-sm font-body whitespace-nowrap block md:hidden'>
                                            <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                        </p>
                                    </div>
                                </div>
                                <p class='text-white text-[16px] font-body font-normal mt-2 md:block hidden'>
                                    <i className="fas fa-map-marker-alt mr-2"></i>
                                    Sindhu bhavan, Ahmedabad
                                </p>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='group-hover:hidden'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon4} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon5} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden group-hover:block'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon2} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon3} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:hidden block mt-3'>
                                    <div className='group-hover:hidden'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon4} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon5} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden group-hover:block'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='relative'>
                                    <img src={img2} alt="" className="w-full mt-4" />
                                </div>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                </div>
                                <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0">Book</button>
                            </div>
                            {/* forth-card */}
                            <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-1 md:p-5 rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary">
                                <div class='flex items-start xl:items-center justify-between gap-3 md:gap-5'>
                                    <h3 class='text-white text-base md:text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                    <div>
                                        <div class='bg-primary py-2 px-3 md:inline-block hidden rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                        <p class='text-secondary text-sm font-body whitespace-nowrap block md:hidden'>
                                            <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                        </p>
                                    </div>
                                </div>
                                <p class='text-white text-[16px] font-body font-normal mt-2 md:block hidden'>
                                    <i className="fas fa-map-marker-alt mr-2"></i>
                                    Sindhu bhavan, Ahmedabad
                                </p>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='group-hover:hidden'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon4} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon5} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden group-hover:block'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon2} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon3} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:hidden block mt-3'>
                                    <div className='group-hover:hidden'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon4} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon5} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden group-hover:block'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='relative'>
                                    <img src={img4} alt="" className="w-full mt-4" />
                                </div>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                </div>
                                <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0">Book</button>
                            </div>
                        </div>
                        {/*--------------- column end------------------------ */}


                        {/*--------------- column start------------------------ */}
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-7 mt-10">
                            {/* first-card */}
                            <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-1 md:p-5 rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary ">
                                <div class='flex items-start xl:items-center justify-between gap-3 md:gap-5'>
                                    <h3 class='text-white text-base md:text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                    <div>
                                        <div class='bg-primary py-2 px-3 md:inline-block hidden rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                        <p class='text-secondary text-sm font-body whitespace-nowrap block md:hidden'>
                                            <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                        </p>
                                    </div>
                                </div>
                                <p class='text-white text-[16px] font-body font-normal mt-2 md:block hidden'>
                                    <i className="fas fa-map-marker-alt mr-2"></i>
                                    Sindhu bhavan, Ahmedabad
                                </p>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='group-hover:hidden'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon4} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon5} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden group-hover:block'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon2} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon3} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:hidden block mt-3'>
                                    <div className='group-hover:hidden'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon4} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon5} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden group-hover:block'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='relative'>
                                    <img src={img5} alt="" className="w-full rounded-lg mt-4" />
                                </div>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                </div>
                                <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0">Book</button>
                            </div>
                            {/* secound-card */}
                            <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-1 md:p-5 rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary">
                                <div class='flex items-start xl:items-center justify-between gap-3 md:gap-5'>
                                    <h3 class='text-white text-base md:text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                    <div>
                                        <div class='bg-primary py-2 px-3 md:inline-block hidden rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                        <p class='text-secondary text-sm font-body whitespace-nowrap block md:hidden'>
                                            <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                        </p>
                                    </div>
                                </div>
                                <p class='text-white text-[16px] font-body font-normal mt-2 md:block hidden'>
                                    <i className="fas fa-map-marker-alt mr-2"></i>
                                    Sindhu bhavan, Ahmedabad
                                </p>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='group-hover:hidden'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon4} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon5} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden group-hover:block'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon2} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon3} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:hidden block mt-3'>
                                    <div className='group-hover:hidden'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon4} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon5} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden group-hover:block'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='relative'>
                                    <img src={img6} alt="" className="w-full rounded-lg mt-4" />
                                </div>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                </div>
                                <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0">Book</button>
                            </div>
                            {/* third-card */}
                            <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-1 md:p-5 rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary">
                                <div class='flex items-start xl:items-center justify-between gap-3 md:gap-5'>
                                    <h3 class='text-white text-base md:text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                    <div>
                                        <div class='bg-primary py-2 px-3 md:inline-block hidden rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                        <p class='text-secondary text-sm font-body whitespace-nowrap block md:hidden'>
                                            <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                        </p>
                                    </div>
                                </div>
                                <p class='text-white text-[16px] font-body font-normal mt-2 md:block hidden'>
                                    <i className="fas fa-map-marker-alt mr-2"></i>
                                    Sindhu bhavan, Ahmedabad
                                </p>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='group-hover:hidden'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon4} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon5} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden group-hover:block'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon2} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon3} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:hidden block mt-3'>
                                    <div className='group-hover:hidden'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon4} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon5} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden group-hover:block'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='relative'>
                                    <img src={img7} alt="" className="w-full rounded-lg mt-4" />
                                </div>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                </div>
                                <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0">Book</button>
                            </div>
                            {/* forth-card */}
                            <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-1 md:p-5 rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary">
                                <div class='flex items-start xl:items-center justify-between gap-3 md:gap-5'>
                                    <h3 class='text-white text-base md:text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                    <div>
                                        <div class='bg-primary py-2 px-3 md:inline-block hidden rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                        <p class='text-secondary text-sm font-body whitespace-nowrap block md:hidden'>
                                            <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                        </p>
                                    </div>
                                </div>
                                <p class='text-white text-[16px] font-body font-normal mt-2 md:block hidden'>
                                    <i className="fas fa-map-marker-alt mr-2"></i>
                                    Sindhu bhavan, Ahmedabad
                                </p>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='group-hover:hidden'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon4} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon5} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden group-hover:block'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon2} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon3} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:hidden block mt-3'>
                                    <div className='group-hover:hidden'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon4} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon5} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden group-hover:block'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='relative'>
                                    <img src={img8} alt="" className="w-full rounded-lg mt-4" />
                                </div>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                </div>
                                <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0">Book</button>
                            </div>
                        </div>
                        {/*--------------- column end------------------------ */}


                        {/*--------------- column start------------------------ */}
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-7 mt-10">
                            {/* first-card */}
                            <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-1 md:p-5 rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary ">
                                <div class='flex items-start xl:items-center justify-between gap-3 md:gap-5'>
                                    <h3 class='text-white text-base md:text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                    <div>
                                        <div class='bg-primary py-2 px-3 md:inline-block hidden rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                        <p class='text-secondary text-sm font-body whitespace-nowrap block md:hidden'>
                                            <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                        </p>
                                    </div>
                                </div>
                                <p class='text-white text-[16px] font-body font-normal mt-2 md:block hidden'>
                                    <i className="fas fa-map-marker-alt mr-2"></i>
                                    Sindhu bhavan, Ahmedabad
                                </p>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='group-hover:hidden'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon4} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon5} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden group-hover:block'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon2} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon3} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:hidden block mt-3'>
                                    <div className='group-hover:hidden'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon4} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon5} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden group-hover:block'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='relative'>
                                    <img src={sartaaj} alt="" className="w-full rounded-lg mt-4" />
                                </div>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                </div>
                                <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0">Book</button>
                            </div>
                            {/* secound-card */}
                            <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-1 md:p-5 rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary">
                                <div class='flex items-start xl:items-center justify-between gap-3 md:gap-5'>
                                    <h3 class='text-white text-base md:text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                    <div>
                                        <div class='bg-primary py-2 px-3 md:inline-block hidden rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                        <p class='text-secondary text-sm font-body whitespace-nowrap block md:hidden'>
                                            <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                        </p>
                                    </div>
                                </div>
                                <p class='text-white text-[16px] font-body font-normal mt-2 md:block hidden'>
                                    <i className="fas fa-map-marker-alt mr-2"></i>
                                    Sindhu bhavan, Ahmedabad
                                </p>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='group-hover:hidden'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon4} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon5} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden group-hover:block'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon2} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon3} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:hidden block mt-3'>
                                    <div className='group-hover:hidden'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon4} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon5} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden group-hover:block'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='relative'>
                                    <img src={img4} alt="" className="w-full rounded-lg mt-4" />
                                </div>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                </div>
                                <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0">Book</button>
                            </div>
                            {/* third-card */}
                            <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-1 md:p-5 rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary">
                                <div class='flex items-start xl:items-center justify-between gap-3 md:gap-5'>
                                    <h3 class='text-white text-base md:text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                    <div>
                                        <div class='bg-primary py-2 px-3 md:inline-block hidden rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                        <p class='text-secondary text-sm font-body whitespace-nowrap block md:hidden'>
                                            <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                        </p>
                                    </div>
                                </div>
                                <p class='text-white text-[16px] font-body font-normal mt-2 md:block hidden'>
                                    <i className="fas fa-map-marker-alt mr-2"></i>
                                    Sindhu bhavan, Ahmedabad
                                </p>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='group-hover:hidden'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon4} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon5} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden group-hover:block'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon2} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon3} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:hidden block mt-3'>
                                    <div className='group-hover:hidden'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon4} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon5} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden group-hover:block'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='relative'>
                                    <img src={img2} alt="" className="w-full mt-4" />
                                </div>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                </div>
                                <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0">Book</button>
                            </div>
                            {/* forth-card */}
                            <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-1 md:p-5 rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary">
                                <div class='flex items-start xl:items-center justify-between gap-3 md:gap-5'>
                                    <h3 class='text-white text-base md:text-2xl font-body font-normal'>Kisi ko batana mat</h3>
                                    <div>
                                        <div class='bg-primary py-2 px-3 md:inline-block hidden rounded-full'>
                                            <p class='text-white text-[14px] font-body whitespace-nowrap'>
                                                <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                            </p>
                                        </div>
                                        <p class='text-secondary text-sm font-body whitespace-nowrap block md:hidden'>
                                            <i className="fas fa-star mr-2 text-secondary"></i><b>4.9</b>
                                        </p>
                                    </div>
                                </div>
                                <p class='text-white text-[16px] font-body font-normal mt-2 md:block hidden'>
                                    <i className="fas fa-map-marker-alt mr-2"></i>
                                    Sindhu bhavan, Ahmedabad
                                </p>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between mt-3">
                                        <div class='bg-black py-1 px-2 inline-block rounded'>
                                            <p class='text-white text-sm font-body font-medium'>
                                                <i className="fas fa-calendar mr-2"></i>
                                                15, March - 1 PM
                                            </p>
                                        </div>
                                        <div className='group-hover:hidden'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon4} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon5} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden group-hover:block'>
                                            <div className='flex items-center gap-2'>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon2} alt="" className='inline-block' />
                                                </div>
                                                <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                    <img src={icon3} alt="" className='inline-block' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:hidden block mt-3'>
                                    <div className='group-hover:hidden'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon4} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-primary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon5} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden group-hover:block'>
                                        <div className='flex items-center gap-2'>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon2} alt="" className='inline-block' />
                                            </div>
                                            <div className="bg-secondary w-[40px] h-[32px] flex items-center justify-center rounded-md p-2">
                                                <img src={icon3} alt="" className='inline-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='relative'>
                                    <img src={img9} alt="" className="w-full rounded-lg mt-4" />
                                </div>
                                <div className='hidden md:block'>
                                    <div className="flex items-center justify-between my-3 px-3 group-hover:py-2 duration-1000 ease-in-out">
                                        <div class='py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white'>
                                            <i className="fas fa-user-friends mr-3"></i>80 k
                                        </div>
                                        <h5 className="text-[32px] font-semibold text-secondary font-body"><b>₹109</b></h5>
                                    </div>
                                </div>
                                <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body w-full hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5 md:mt-0">Book</button>
                            </div>
                        </div>
                        {/*--------------- column end------------------------ */}
                    </div>
                </div>
            </section>
            <Bussines />





        </>
    );
}

export default Exhibitions;
